export interface ModelContacto {
    nombres: string,
    apellidos: string,
    telefono: string,
    correo: string,
    mensaje: string
}

export const newContacto: ModelContacto = {
    nombres: "",
    apellidos: "",
    telefono: "",
    correo: "",
    mensaje: ""
};