import React from 'react';
import kas1 from '../../../../assets/img/movil/kapersky.jpg';
import kas2 from '../../../../assets/img/movil/kars.jpg';
import kas3 from '../../../../assets/img/movil/premiada.jpg';
import kas4 from '../../../../assets/img/movil/recomendado.jpg';
import kas5 from '../../../../assets/img/movil/transparente.jpg';
import './CMKaspersky.css';

function CMKaspersky() {

    return (
        <div id="CMKaspersky">
            <section id="Kas__head">
                <h2>Kaspersky Antivirus</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Servicios</li>
                    <li>/</li>
                    <li>Kaspersky Antivirus</li>
                </ul>
            </section>
            <section id="Kasp__section2">
                <figure id="Kasp-content">
                    <img src={kas1} />
                </figure>
                <div id="Kasp-text">
                    <p id="Kasp-text__p1">Ciberseguridad sencilla para las empresas en crecimiento.</p>
                    <p id="Kasp-text__p2">La mejor seguridad disponible facilita el trabajo de los sobrecargados departamentos de TI, no lo dificulta.
                    Si su empresa está creciendo rápido o si se encuentra en plena transformación digital, es posible que los recursos estén continuamente sobrecargados.
                    Por lo tanto, debe trabajar de manera inteligente: seleccione soluciones de endpoint que ofrecen protección instantánea y
                        están listas para escalarse cuando lo necesite.</p>
                </div>
            </section>
            <section id="Kasp__section3">
                <div id="Kasps3-title">
                    <h2>¿Qué protección es mejor para su negocio?</h2>
                </div>
                <div id="Kasps3-image">
                    <figure>
                        <img src={kas2} />
                    </figure>
                </div>
            </section>
            <section id="Kasp__section4">
                <h2>¿Qué protección es mejor para su negocio?</h2>
                <div id="Kasp-icons">
                    <div id="Kasps4-item1">
                        <figure id="Kasps4-item1__figure">
                            <img src={kas3} />
                        </figure>
                        <div id="Kasps4-item1__content">
                            <h2>La más probada. La más premiada.</h2>
                            <p>Kaspersky Lab ha conseguido la mayor cantidad de primeros lugares en pruebas independientes que cualquier otro proveedor de seguridad.
                            Y eso lo conseguimos todos los años.</p>
                        </div>
                    </div>
                    <div id="Kasps4-item2">
                        <figure id="Kasps4-item2__figure">
                            <img src={kas4} />
                        </figure>
                        <div id="Kasps4-item2__content">
                            <h2>El más recomendado</h2>
                            <p>Kaspersky Lab vuelve a ser nombrado en los Peer Insights Customers Choise de Gartner del 2018 por las plataformas de protección de endpoints,
                            con una alta puntuación de satisfacción de los clientes de 4.7 de 5.</p>
                        </div>
                    </div>
                    <div id="Kasps4-item3">
                        <figure>
                            <img src={kas5} />
                        </figure>
                        <div id="Kasps4-item3__content">
                            <h2>Más transparente</h2>
                            <p>Con nuestro primer centro de transparencia ahora activo y un procesamiento estadístico situado en Suiza, la soberanía de sus datos se garantiza de maneras
                            que ningún otro proveedor puede igualar.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default CMKaspersky;