import React from 'react';
import bit1 from '../../../../assets/img/movil/13.jpg';
import bit2 from '../../../../assets/img/movil/16.jpg';
import bit3 from '../../../../assets/img/movil/4.png';
import bit4 from '../../../../assets/img/movil/5.png';
import bit5 from '../../../../assets/img/movil/17.png';
import bit6 from '../../../../assets/img/movil/7.png';
import bit7 from '../../../../assets/img/movil/8.png';
import bit8 from '../../../../assets/img/movil/9.jpg';
import bit9 from '../../../../assets/img/movil/01.png';
import bit10 from '../../../../assets/img/movil/02.jpg';
import bit11 from '../../../../assets/img/movil/11.jpg';
import bit12 from '../../../../assets/img/movil/bidefender-04.jpg';
import './CMBitdefender.css';

function CMBitdefender() {

    return (
        <div id="CMBitdefender">
            <section id="Bit__head">
                <h2>Bitdefender Antivirus</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Servicios</li>
                    <li>/</li>
                    <li>Bitdefender Antivirus</li>
                </ul>
            </section>
            <section id="Bit__section2">
                <figure id="Bit-content">
                    <img src={bit1} />
                </figure>
                <div id="Bit-text">
                    <p id="Bit-text__p1">Proteja su negocio con una seguridad por capas de última generación.</p>
                    <p id="Bit-text__p2">Con la mejor protección antivirus y contra ransomware frente a ataques de días cero y reversión de daños.</p>
                </div>
            </section>
            <section id="Bit__section3">
                <div id="Bits3-content">
                    <figure>
                        <img src={bit2} />
                    </figure>
                    <div>
                        <h2>Desafíos para la seguridad</h2>
                        <p>Con la llegada del malware al mundo digital, las pequeñas y medianas empresas pueden convertirse en un blanco fácil para los piratas informáticos.
                        Los estudios muestran que más del 50 % de las pequeñas y medianas empresas son víctimas de ataques informáticos. No obstante, contando con la seguridad adecuada,
                            incluso las pequeñas empresas pueden permanecer protegidas frente a todo tipo de ataques, desde el ransomware hasta las amenazas persistentes avanzadas.</p>
                    </div>
                </div>
                <div id="Bits3-text">
                    <p id="Bits3-text__p1">Bitdefender emplea diversas técnicas y capas para salvaguardar su negocio y protege todo lo que lo mantiene en marcha: estaciones de trabajo, servidores, buzones y dispositivos móviles.</p>
                    <p id="Bits3-text__p2">Es increíblemente fácil de instalar y consume pocos recursos.</p>
                </div>
            </section>
            <section id="Bit__section4">
                <div id="Bits4-content1">
                    <div id="Bits4-content1__i1">
                        <h2>Mejor protección y mejor rendimiento.</h2>
                        <p>Mediante el uso de tecnologías avanzadas basadas en el comportamiento, Bitdefender obtiene las mejores puntuaciones en cuanto a detección de amenazas en las
                        pruebas independientes. Se ha demostrado que la protección y el rendimiento líderes de GravityZone contribuyen a minimizar el riesgo de que un fallo de seguridad
                        pudiera afectar a su negocio o ralentizar los sistemas de sus empleados.</p>
                    </div>
                    <div id="Bits4-content1__i2">
                        <figure>
                            <img src={bit3} />
                        </figure>
                        <figure>
                            <img src={bit3} />
                        </figure>
                        <figure>
                            <img src={bit3} />
                        </figure>
                        <figure>
                            <img src={bit4} />
                        </figure>
                        <figure>
                            <img src={bit5} />
                        </figure>
                        <figure>
                            <img src={bit6} />
                        </figure>
                        <figure>
                            <img src={bit7} />
                        </figure>
                    </div>
                </div>
                <div id="Bits4-content2">
                    <figure>
                        <img src={bit8} />
                    </figure>
                </div>
            </section>
            <section id="Bit__section5">
                <div id="Bits5-content1">
                    <figure>
                        <img src={bit9} />
                    </figure>
                </div>
                <div id="Bits5-content2">
                    <h2>Implementación y administración de la seguridad simplificadas</h2>
                    <p>Puede utilizar la consola de administración alojada en la nube o implementar localmente la consola. La consola on-premise de GravityZone,
                    basada en máquinas Linux reforzadas y encapsuladas en appliances virtuales, queda configurada y lista para utilizarse en menos de treinta minutos.
                        El agente de Bitdefender detecta y elimina automáticamente la solución antivirus anterior durante la instalación.</p>
                </div>
            </section>
            <section id="Bit__section6">
                <div id="Bits6-content1">
                    <h2>Administración unificada para todos sus endpoints</h2>
                    <p>Utilice una única consola unificada para administrar la protección optimizada para equipos de escritorio y servidores,
                        máquinas físicas y virtuales, móviles y correo electrónico.</p>
                </div>
                <div id="Bits6-content2">
                    <figure>
                        <img src={bit10} />
                    </figure>
                </div>
            </section>
            <section id="Bit__section7">
                <div id="Bits7-content1">
                    <h2>Lo último en protección, detección, respuesta y análisis de riesgos avanzados, diseñado para abordar todo el ciclo de vida de la amenaza.</h2>
                    <p>Utiliza una arquitectura adaptativa por niveles de seguridad que incluye el control de los endpoints y la prevención, detección, reparación y visibilidad.</p>
                </div>
                <div id="Bits7-content2">
                    <figure>
                        <img src={bit11} />
                    </figure>
                </div>
            </section>
            <section id="Bit__section8">
                <div id="Bits8-content1">
                    <h2>Comparación de soluciones</h2>
                    <p>GravityZone es una solución de negocios que se puede instalar localmente o alojarse en Bitdefender. Cada una de las opciones que se indican a continuación
                    incluyen la protección de múltiples capas con un demostrado aprendizaje automático, heurística avanzada, defensa contra ransomware, potente antiexploit, firmas,
                        cortafuego y control de dispositivos.</p>
                </div>
                <div id="Bits8-content2">
                    <figure>
                        <img src={bit12} />
                    </figure>
                </div>
            </section>
        </div>
    );
}

export default CMBitdefender;