import React from "react";
import { View } from '../../../components/templates/View';
import { CDHelpDesk, CMHelpDesk } from '../organisms';
import { useViewport } from "../../../hooks";

const HelpDesk = () => {

    const { width } = useViewport();

    return (
        width > 850 ?
            <View children={<CDHelpDesk />} />
            :
            <View children={<CMHelpDesk />} />
    );
}

export default HelpDesk;