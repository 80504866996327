import React from "react";
import { View } from '../../../components/templates/View';
import { CDHardware, CMHardware } from '../organisms';
import { useViewport } from "../../../hooks";

const Hardware = () => {

    const { width } = useViewport();

    return(
        width > 850 ?
            <>
                <View children={ <CDHardware />} />
            </>
        :
            <>
                <View children={ <CMHardware />} />
            </>
    );
}

export default Hardware;