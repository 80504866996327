import React from 'react';
import bit1 from '../../../../assets/img/desktop/13.jpg';
import bit2 from '../../../../assets/img/desktop/16.jpg';
import bit3 from '../../../../assets/img/desktop/4.png';
import bit4 from '../../../../assets/img/desktop/5.png';
import bit5 from '../../../../assets/img/desktop/17.png';
import bit6 from '../../../../assets/img/desktop/7.png';
import bit7 from '../../../../assets/img/desktop/8.png';
import bit8 from '../../../../assets/img/desktop/9.jpg';
import bit9 from '../../../../assets/img/desktop/01.png';
import bit10 from '../../../../assets/img/desktop/02.jpg';
import bit11 from '../../../../assets/img/desktop/11.jpg';
import bit12 from '../../../../assets/img/desktop/bidefender-04.jpg';
import './CDBitdefender.css';

function CDBitdefender() {
    
    return (
        <main id="CDBitdefender">
            <section id="CDB-c1">
                <h2>Bitdefender Antivirus</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Servicios</li>
                    <li>/</li>
                    <li>Bitdefender Antivirus</li>
                </ul>
            </section>
            <section id="CDB-c2">
                <figure id="CDB-c2__image">
                    <img src={bit1} />
                </figure>
                <div id="CDB-c2__text">
                    <h4 >Proteja su negocio con una seguridad por capas de última generación.</h4>
                    <p >Con la mejor protección antivirus y contra ransomware frente a ataques de días cero y reversión de daños.</p>
                </div>
            </section>
            <section id="CDB-c3">
                <div id="CDB-c3__image">
                    <figure>
                        <img src={bit2} />
                    </figure>
                    <div>
                        <h2>Desafíos para la seguridad</h2>
                        <p>Con la llegada del malware al mundo digital, las pequeñas y medianas empresas pueden convertirse en un blanco fácil para los piratas informáticos.
                            Los estudios muestran que más del 50 % de las pequeñas y medianas empresas son víctimas de ataques informáticos. No obstante, contando con la seguridad
                            adecuada,incluso las pequeñas empresas pueden permanecer protegidas frente a todo tipo de ataques, desde el ransomware hasta las amenazas
                            persistentes avanzadas.</p>
                    </div>
                </div>
                <div id="CDB-c3__text">
                    <h4>Bitdefender emplea diversas técnicas y capas para salvaguardar su negocio y protege todo lo que lo mantiene en marcha: estaciones de
                        trabajo, servidores, buzones y dispositivos móviles.</h4>
                    <p >Es increíblemente fácil de instalar y consume pocos recursos.</p>
                </div>
            </section>
            <section id="CDB-c4">
                <section>
                    <div id="CDB-c4__text">
                        <div id="CDB-c4__text--parrafos">
                            <h2>Mejor protección y mejor rendimiento.</h2>
                            <p>Mediante el uso de tecnologías avanzadas basadas en el comportamiento, Bitdefender obtiene las mejores puntuaciones en cuanto a detección de amenazas en las
                                pruebas independientes. Se ha demostrado que la protección y el rendimiento líderes de GravityZone contribuyen a minimizar el riesgo de que un fallo de seguridad
                                pudiera afectar a su negocio o ralentizar los sistemas de sus empleados.</p>
                        </div>
                        <div id="CDB-c4__text--images">
                            <figure>
                                <img src={bit3} />
                            </figure>
                            <figure>
                                <img src={bit3} />
                            </figure>
                            <figure>
                                <img src={bit3} />
                            </figure>
                            <figure>
                                <img src={bit4} />
                            </figure>
                            <figure>
                                <img src={bit5} />
                            </figure>
                            <figure>
                                <img src={bit6} />
                            </figure>
                            <figure>
                                <img src={bit7} />
                            </figure>
                        </div>
                    </div>
                    <div id="CDB-c4__image">
                        <figure>
                            <img src={bit8} />
                        </figure>
                    </div>
                </section>
            </section>
            <section id="CDB-c5">
                <section>
                    <div id="CDB-c5__image">
                        <figure>
                            <img src={bit9} />
                        </figure>
                    </div>
                    <div id="CDB-c5__text">
                        <h2>Implementación y administración de la seguridad simplificadas</h2>
                        <p>Puede utilizar la consola de administración alojada en la nube o implementar localmente la consola. La consola on-premise de GravityZone,
                            basada en máquinas Linux reforzadas y encapsuladas en appliances virtuales, queda configurada y lista para utilizarse en menos de treinta minutos.
                            El agente de Bitdefender detecta y elimina automáticamente la solución antivirus anterior durante la instalación.</p>
                    </div>
                </section>
            </section>
            <section id="CDB-c6">
                <section>
                    <div id="CDB-c6__text">
                        <h2>Administración unificada para todos sus endpoints</h2>
                        <p>Utilice una única consola unificada para administrar la protección optimizada para equipos de escritorio y servidores,
                            máquinas físicas y virtuales, móviles y correo electrónico.</p>
                    </div>
                    <div id="CDB-c6__image">
                        <figure>
                            <img src={bit10} />
                        </figure>
                    </div>
                </section>
            </section>
            <section id="CDB-c7">
                <div id="CDB-c7__text">
                    <h2>Lo último en protección, detección, respuesta y análisis de riesgos avanzados, diseñado para abordar todo el ciclo de vida de la amenaza.</h2>
                    <p>Utiliza una arquitectura adaptativa por niveles de seguridad que incluye el control de los endpoints y la prevención, detección, reparación y visibilidad.</p>
                </div>
                <div id="CDB-c7__image">
                    <figure>
                        <img src={bit11} />
                    </figure>
                </div>
            </section>
            <section id="CDB-c8">
                <section>
                    <div id="CDB-c8__text">
                        <h2>Comparación de soluciones</h2>
                        <p>GravityZone es una solución de negocios que se puede instalar localmente o alojarse en Bitdefender. Cada una de las opciones que se indican a continuación
                            incluyen la protección de múltiples capas con un demostrado aprendizaje automático, heurística avanzada, defensa contra ransomware, potente antiexploit, firmas,
                            cortafuego y control de dispositivos.</p>
                    </div>
                    <div id="CDB-c8__image">
                        <figure>
                            <img src={bit12} />
                        </figure>
                    </div>
                </section>
            </section>
        </main>
    );
}

export default CDBitdefender;