import { useState, useEffect } from 'react';
import partner1 from '../../../../assets/img/desktop/Zebra_logo.png';
import partner2 from '../../../../assets/img/desktop/MicrosoftTeams-image.png';
import partner3 from '../../../../assets/img/desktop/Microsoft_logo_(2012).svg.png';
import partner4 from '../../../../assets/img/desktop/Partner4.jpg';
import partner5 from '../../../../assets/img/desktop/Partner6.jpg';
import partner6 from '../../../../assets/img/desktop/Bitdefender-Logo.wine.png';
import partner7 from '../../../../assets/img/desktop/Autodesk_Logo.svg.png';
import partner8 from '../../../../assets/img/desktop/351497.png';
import partner9 from '../../../../assets/img/desktop/350655.png';
import partner10 from '../../../../assets/img/desktop/2048px-HP_New_Logo_2D.svg.png';
import partner11 from '../../../../assets/img/desktop/1280px-Epson_logo.svg.png';
import partner12 from '../../../../assets/img/desktop/1200px-Kaspersky_Lab_logo.svg.png';
import partner13 from '../../../../assets/img/desktop/logo_heimdal_security.png';
import partner14 from '../../../../assets/img/desktop/Lenovo_logo_2015.svg.png';
import partner15 from '../../../../assets/img/desktop/Partner15.jpg';
import partner16 from '../../../../assets/img/desktop/Dell_Logo.svg.png';
import './CDNosotros.css';

function CDNosotros() {
    const [stPartners] = useState([partner1, partner2, partner3, partner4, partner5, partner6, partner7, partner8, partner9, partner10, partner11, partner12, partner13, partner14, partner15, partner16]);

    useEffect(() => {
        let idInterval = setInterval(() => {
            if (window.location.pathname === "/Nosotros") {
                try {
                    moverCarruselpartner("derecha");
                } catch(e) {
                    window.location.reload();
                }
                
            } else {
                window.clearInterval(idInterval);
            }
        }, 5000);
    }, [])

    function moverCarruselpartner(direccion:string) {
        let stContenidoPartner:any = document.getElementById("CDH-c8Partner");

        if (direccion === "derecha") {
            let itemPrimeropartner = stContenidoPartner.childNodes[0];
            if (window.innerWidth < 1000) {
                stContenidoPartner.style.marginLeft = "-66.66%";
            }
            else {
                stContenidoPartner.style.marginLeft = "-50%";
            }
            stContenidoPartner.style.transition = "all .5s";

            setTimeout(() => {
                stContenidoPartner.style.transition = "none";
                stContenidoPartner.insertAdjacentElement('beforeend', itemPrimeropartner);

                if (window.innerWidth < 1000) {
                    stContenidoPartner.style.marginLeft = "-33.33%";
                }
                else {
                    stContenidoPartner.style.marginLeft = "-25%";
                }
            }, 500);
        }
        else {
            let itempartner = stContenidoPartner.childNodes;
            let itemfinalpartner = itempartner[itempartner.length - 1];

            stContenidoPartner.style.marginLeft = "0%";
            stContenidoPartner.style.transition = "all 0.5s"

            setTimeout(() => {
                stContenidoPartner.style.transition = "none";
                stContenidoPartner.insertAdjacentElement('afterbegin', itemfinalpartner);
                stContenidoPartner.style.marginLeft = "-33.33%";
            }, 500);
        }
    }

    return (
        <main id="CDNosotros">
            <section id="CDN-c1">
                <h2>Nosotros</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Nosotros</li>
                </ul>
            </section>
            <section id="CDH-c4">
                <div id="CDH-c4__text">
                    <div id="CDH-c4__text--p1">
                        <p>NOSOTROS</p>
                    </div>
                    <div id="CDH-c4__text--p2">
                        <p>Somos un equipo apasionado y<strong> dedicado de asesores y consultores.</strong></p>
                    </div>
                    <div id="CDH-c4__text--p3">
                        <p>Comunicaciones, Infraestructura y Seguridad Informática</p>
                    </div>
                </div>
                <div id="CDH-c4__bar">
                    <ul>
                        <li>
                            <p>Bitdefender Antivirus 95% </p>
                            <div id="bit-95"></div>
                        </li>
                        <li>
                            <p>Kapersky Antivirus 85% </p>
                            <div id="kap-85"></div>
                        </li>
                        <li>
                            <p>Licenciamiento Microsoft 90% </p>
                            <div id="mic-90"></div>
                        </li>
                        <li>
                            <p>Servicios de Infraestructura 85% </p>
                            <div id="inf-85"></div>
                        </li>
                        <li>
                            <p>Distribucion de Hardware 90% </p>
                            <div id="har-90"></div>
                        </li>
                        <li>
                            <p>Soluciones de Networking 90% </p>
                            <div id="net-90"></div>
                        </li>
                    </ul>
                </div>
            </section>
            <section id="CDN-c3">
                <section >
                    <div id="CDN-c3__text">
                        <div id="CDN-c3__text--title">
                            <p>Filosofía</p>
                            <div></div>
                        </div>
                        <div id="CDN-c3__text--parrafo">
                            <p>Nuestra filosofía de trabajo apunta siempre a la excelencia, compromiso, integridad, flexibilidad y con total entrega en nuestros clientes,
                                características que nos dan un crédito más de valoración y confianza.</p>
                            <p>Su satisfacción es nuestra filosofía. A sumimos cada proyecto como un nuevo reto, con responsabilidad y eficiencia tenemos muy en claro que para su satisfacción es
                            necesario articular un protocolo que incluye una serie de aspectos: como una estrecha colaboración que nos permite detectar sus necesidades, ofrecer soluciones a medida y
                                potenciar las relaciones duraderas.</p>
                        </div>
                    </div>
                    <div id="CDN-c3__text2">
                        <div id="CDN-c3__text2--t1">
                            <div id="CDN-c3__text2--t1__title">
                                <p>Visión</p>
                                <div></div>
                            </div>
                            <div id="CDN-c3__text2--t1__parrafo">
                                <p>Seguir siendo el asesor de soluciones de TI más influyente y respetado de las empresas del medio.</p>
                            </div>
                        </div>
                        <div id="CDN-c3__text2--t2">
                            <div id="CDN-c3__text2--t2__title">
                                <p>Misión</p>
                                <div></div>
                            </div>
                            <div id="CDN-c3__text2--t2__parrafo">
                                <p>Brindar soluciones de valor que permitan la optimización y productividad de la plataforma tecnológica de nuestros clientes, logrando alta satisfacción y fidelización.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <section id="CDN-c4">
                <section>
                    <div id="CDH-c8__partner" >
                        <div id="CDH-c8__partner--text">
                            <h3>Nuestros <strong>Partners.</strong></h3>
                            <div>
                                <ul id="CDH-c8Partner">
                                    {
                                        stPartners.map((item, index) => (
                                            <li key={index} className="CDH-c8__partner--item">
                                                <img src={item} alt="Infrasol Partners"/>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </main>
    );
}

export default CDNosotros;