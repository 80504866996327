import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home, Nosotros, Bitdefender, Kaspersky, Microsoft, Infraestructura, Networking, Hardware, Antispam, HelpDesk, Contacto } from '../features';

function MainRoutes() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/Nosotros' element={<Nosotros />} />
                <Route path='/Bitdefender' element={<Bitdefender />} />
                <Route path='/Kaspersky' element={<Kaspersky />} />
                <Route path='/Microsoft' element={<Microsoft />} />
                <Route path='/Infraestructura' element={<Infraestructura />} />
                <Route path='/Networking' element={<Networking />} />
                <Route path='/Hardware' element={<Hardware />} />
                <Route path='/Antispam' element={<Antispam />} />
                <Route path='/HelpDesk' element={<HelpDesk />} />
                <Route path='/Contacto' element={<Contacto />} />
            </Routes>
        </BrowserRouter>
    );
}

export default MainRoutes;

/*
<Route path='/Nosotros' element={<Nosotros />} />
                <Route path='/Bitdefender' element={<Bitdefender />} />
                <Route path='/Kaspersky' element={<Kaspersky />} />
                <Route path='/Microsoft' element={<Microsoft />} />
                <Route path='/Infraestructura' element={<Infraestructura />} />
                <Route path='/Networking' element={<Networking />} />
                <Route path='/Hardware' element={<Hardware />} />
                <Route path='/Antispam' element={<Antispam />} />
                <Route path='/HelpDesk' element={<HelpDesk />} />
                <Route path='/Contacto' element={<Contacto />} />
*/