import React from "react";
import { openNewTab } from "../../../../utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { API_WHATSAPP } from '../../../../constants/public.constant'
import { ButtonMessenger } from '../../../molecules';
import './CDSocial.css';

function CDSocial() {

    function consultarWhatsapp() {
        openNewTab(API_WHATSAPP);
    }

    return (
        <div id="CDSocial">
            <div id="CDFacebook">
                <ButtonMessenger />
            </div>

            <div id="CDWhatsapp" onClick={consultarWhatsapp}>
                <FontAwesomeIcon icon={faWhatsapp} />
                <p>Contáctenos vía WhatsApp</p>
            </div>
        </div>
    );
}

export default CDSocial;