import React from 'react';
import inf1 from '../../../../assets/img/desktop/infraestructura.jpg';
import inf2 from '../../../../assets/img/desktop/infraestructura2.jpg';
import './CDInfraestructura.css';

function CDInfraestructura() {

    return (
        <main id="CDInfraestructura" >
            <section id="CDI-c1">
                <h2>Servicios de Infraestructura</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Servicios</li>
                    <li>/</li>
                    <li>Servicios de Infraestructura</li>
                </ul>
            </section>
            <section id="CDI-c2">
                <figure id="CDI-c2__image">
                    <img src={inf1} />
                </figure>
                <div id="CDI-c2__text">
                    <h4>Damos respuesta a las necesidades de la <strong>plataforma de TI de las empresas.</strong></h4>
                    <p>Teniendo en cuenta que las prácticas inadecuadas del uso de la tecnología pueden afectar cada aspecto de la organización,
                        es necesario contar con una gestión segura y eficiente. La lata disponibilidad, la sensibilidad y la capacidad son requisitos no negociables de
                        las corporaciones y requieren experiencia y conocimientos específicos.</p>
                </div>
            </section>
            <section id="CDI-c3">
                <div id="CDI-c3__text">
                    <div id="CDI-c3__text--parrafos">
                        <p>A través de nuestro modelo de entrega único, <strong>INFRASOL</strong> provee servicios rentables; basados en Acuerdos de Niveles de Servicio (SLAs); soportados
                            por una metodología de entrega madura, alineada a las mejores prácticas ITIL y soportado en un sólido programa de calidad.</p>
                        <p>Contamos con experiencia en planificación, diseño, implementación y gestión de proyectos de soluciones informáticas
                            para resolver los requerimientos técnicos o de negocios específicos de clientes, integrando tecnología y servicios.</p>
                        <p>Contamos con la experiencia, conocimientos y capacidades para implementar Hardware, Software y Comunicaciones y alinearlos
                            con la estrategia de negocios de nuestros clientes.</p>
                        <p className="parrafo-fases">Las tres fases: Diseño, Operación e Implementación tienen las siguientes características:</p>
                    </div>
                    <div id="CDI-c3__text--image">
                        <figure>
                            <img src={inf2} />
                        </figure>
                    </div>
                </div>
                <div id="CDI-c3__fases">
                    <div id="CDI-c3__fases--item1">
                        <div className="item1-fases">
                            <h3>F1 - Diseño</h3>
                            <div></div>
                            <p>Diseño, Implementación y administración de plataformas Microsoft con suite SYSTEM CENTER, Hyper-V,
                                Exchange Server, SharePoint Server, Lync Server, SQL Server, TMG Server y servicios de networking y Controles de acceso.</p>
                        </div>
                        <div className="item1-fases">
                            <h3>F2 - Operación</h3>
                            <div></div>
                            <p>Gestión documental y outsourcing de impresión, sistemas de mensajería unificada para correo electrónico, videoconferencia y telefonía IP.</p>
                        </div>
                    </div>
                    <div id="CDI-c3__fases--item2">
                        <h3>F3 - Implementación</h3>
                        <div></div>
                        <ul>
                            <li>Servidores proxy, servidores de archivos, print server, servidores de seguridad , Antivirus ,Firewalls, VLANs, Acceso remoto, Conexiones VPN LAN to LAN.</li>
                            <li>Equilibrio de cargas en alta disponibilidad sobre servidores, servicios y almacenamiento.</li>
                            <li>Sistemas operativos, bases de datos ,replicación de servidores, Network Load Balancing y Fail Over.</li>
                            <li>Gestión de inventarios sobre hardware, software y control de licenciamiento, gestión de mesas de ayuda.</li>
                            <li>Instalación y retiro de UPS y Baterías, de las mejores marcas del mercado: APC, Forza, Ritar, entre otros.</li>
                            <li>Almacenamiento a disco con conectividad Fiber Channel, iSCSI, SAS y Ethernet, con discos duros SAS, SATA, Estado Solido.</li>                          
                        </ul>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default CDInfraestructura;