import inf1 from '../../../../assets/img/movil/infraestructura.jpg';
import inf2 from '../../../../assets/img/movil/infraestructura2.jpg';
import './CMInfraestructura.css';

function CMInfraestructura() {

    return (
        <div id="CMInfraestructura">
            <section id="Inf__head">
                <h2>Servicios de Infraestructura</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Servicios</li>
                    <li>/</li>
                    <li>Servicios de Infraestructura</li>
                </ul>
            </section>
            <section id="Inf__section2">
                <figure id="Inf-content">
                    <img src={inf1} />
                </figure>
                <div id="Inf-text">
                    <p id="Inf-text__p1">Damos respuesta a las necesidades de la <strong>plataforma de TI de las empresas.</strong></p>
                    <p id="Inf-text__p2">Teniendo en cuenta que las prácticas inadecuadas del uso de la tecnología pueden afectar cada aspecto de la organización,
                    es necesario contar con una gestión segura y eficiente. La lata disponibilidad, la sensibilidad y la capacidad son requisitos no negociables de
                        las corporaciones y requieren experiencia y conocimientos específicos.</p>
                </div>
            </section>
            <section id="Inf__section3">
                <div id="Infs3-content1">
                    <div id="Inf3-content1__text">
                        <p id="Inf3-content1__text--p1">A través de nuestro modelo de entrega único, <strong>INFRASOL</strong> provee servicios rentables; basados en Acuerdos de Niveles de Servicio (SLAs); soportados
                            por una metodología de entrega madura, alineada a las mejores prácticas ITIL y soportado en un sólido programa de calidad.</p>
                        <p id="Inf3-content1__text--p2">Contamos con experiencia en planificación, diseño, implementación y gestión de proyectos de soluciones informáticas
                            para resolver los requerimientos técnicos o de negocios específicos de clientes, integrando tecnología y servicios.</p>
                        <p id="Inf3-content1__text--p3">Contamos con la experiencia, conocimientos y capacidades para implementar Hardware, Software y Comunicaciones y alinearlos
                            con la estrategia de negocios de nuestros clientes.</p>
                        <p id="Inf3-content1__text--p4">Las tres fases: Diseño, Operación e Implementación tienen las siguientes características:</p>
                    </div>
                    <div id="Inf3-content1__image">
                        <figure>
                            <img src={inf2} />
                        </figure>
                    </div>
                </div>
                <div id="Infs3-content2">
                    <div id="Infs3-content2__text1">
                        <div id="Infs3-content2__text1--i1">
                            <h3>F1 - Diseño</h3>
                            <div></div>
                            <p>Diseño, Implementación y administración de plataformas Microsoft con suite SYSTEM CENTER, Hyper-V,
                                Exchange Server, SharePoint Server, Lync Server, SQL Server, TMG Server y servicios de networking y Controles de acceso.</p>
                        </div>
                        <div id="Infs3-content2__text1--i2">
                            <h3>F2 - Operación</h3>
                            <div></div>
                            <p>Gestión documental y outsourcing de impresión, sistemas de mensajería unificada para correo electrónico, videoconferencia y telefonía IP.</p>
                        </div>
                    </div>
                    <div id="Infs3-content2__text2">
                        <h3 id="Infs3-content2__text2--i3">F3 - Implementación</h3>
                        <div></div>
                        <ul>
                            <li>Servidores proxy, servidores de archivos, print server, servidores de seguridad , Antivirus ,Firewalls, VLANs, Acceso remoto, Conexiones VPN LAN to LAN.</li>
                            <li>Equilibrio de cargas en alta disponibilidad sobre servidores, servicios y almacenamiento.</li>
                            <li>Sistemas operativos, bases de datos ,replicación de servidores, Network Load Balancing y Fail Over.</li>
                            <li>Gestión de inventarios sobre hardware, software y control de licenciamiento, gestión de mesas de ayuda.</li>
                            <li>Instalación y retiro de UPS y Baterías, de las mejores marcas del mercado: APC, Forza, Ritar, entre otros.</li>
                            <li>Almacenamiento a disco con conectividad Fiber Channel, iSCSI, SAS y Ethernet, con discos duros SAS, SATA, Estado Solido.</li>                        
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default CMInfraestructura;