import React from "react";
import { MessengerChat } from "react-messenger-chat-plugin";
import { API_FACEBOOK_PROPS } from '../../../constants/public.constant';
import "./ButtonMessenger.css";

export function ButtonMessenger() {

    return(
        <MessengerChat 
            pageId = { API_FACEBOOK_PROPS.pageId }
            language = { API_FACEBOOK_PROPS.language }
            themeColor= { API_FACEBOOK_PROPS.themeColor }
            loggedInGreeting= { API_FACEBOOK_PROPS.loggedInGreeting }
            loggedOutGreeting= { API_FACEBOOK_PROPS.loggedOutGreeting }/>
    );
}