import { useState } from 'react';
import { ModelContacto, newContacto } from '../../../../models/Contacto.model';
import { SolicitarInformacion } from '../../../../services'
import mapa from '../../../../assets/img/movil/map.svg';
import telefono from '../../../../assets/img/movil/telefono.svg';
import email from '../../../../assets/img/movil/email.svg';
import alerta from '../../../../assets/img/movil/advertencia.svg';
import './CMContacto.css';

function CMContacto() {

    const [objContacto, setObjContacto] = useState<ModelContacto>(newContacto);
    const [Calerta01, setCalerta01] = useState("Calerta01-off");
    const [Calerta02, setCalerta02] = useState("Calerta02-off");
    const [Calerta03, setCalerta03] = useState("Calerta03-off");
    const [Calerta04, setCalerta04] = useState("Calerta04-off");
    const [Calerta05, setCalerta05] = useState("Calerta05-off");

    function cargardatos(event:any) {
        var target = event.target;
        var value = target.value;
        var name = target.name;

        if (name === "telefono") {
            if (value.length <= 9) {
                setObjContacto({
                    ...objContacto,
                    [name]: value
                })
            }
        } else {
            setObjContacto({
                ...objContacto,
                [name]: value
            })
        }

        var validar = false;

        while (validar === false) {
            switch (name) {
                case "nombres":
                    if (value !== "") {
                        setCalerta01("Calerta01-off");
                        validar = true;
                    }
                    else {
                        validar = true;
                    }
                    break;
                case "apellidos":
                    if (value !== "") {
                        setCalerta02("Calerta02-off");
                        validar = true;
                    }
                    else {
                        validar = true;
                    }
                    break;
                case "telefono":
                    if (value !== "") {
                        setCalerta03("Calerta03-off");
                        validar = true;
                    }
                    else {
                        validar = true;
                    }
                    break;
                case "correo":
                    if (value !== "") {
                        setCalerta04("Calerta04-off");
                        validar = true;
                    }
                    else {
                        validar = true;
                    }
                    break;
                case "mensaje":
                    if (value !== "") {
                        setCalerta05("Calerta05-off");
                        validar = true;
                    }
                    else {
                        validar = true;
                    }
                    break;
                default:
                    validar = true;
                    break;
            }
        }
    }

    function validarcontactoM() {
        if (objContacto.nombres === "" && objContacto.apellidos === "" && objContacto.telefono === "" && objContacto.correo === "" && objContacto.mensaje === "") {
            setCalerta01("Calerta01-on");
        }
        else if (objContacto.nombres !== "" && objContacto.apellidos !== "" && objContacto.telefono !== "" && objContacto.correo !== "" && objContacto.mensaje !== "") {
            
            let request:any = SolicitarInformacion(objContacto);
            
            setTimeout(() => {
                if (request) {
                    setObjContacto({
                        nombres: "",
                        apellidos: "",
                        telefono: "",
                        correo: "",
                        mensaje: ""
                    });
                }
            }, 3000);
        }
        else {
            var interruptor = true;
            while (interruptor === true) {
                if (objContacto.nombres === "") {
                    setCalerta01("Calerta01-on");
                    interruptor = false;
                    break;
                } else {
                    setCalerta01("Calerta01-off");
                    interruptor = true;
                }
                if (objContacto.apellidos === "") {
                    setCalerta02("Calerta02-on");
                    interruptor = false;
                    break;
                } else {
                    setCalerta02("Calerta02-off");
                    interruptor = true;
                }
                if (objContacto.telefono === "") {
                    setCalerta03("Calerta03-on");
                    interruptor = false;
                    break;
                } else {
                    setCalerta03("Calerta03-off");
                    interruptor = true;
                }
                if (objContacto.correo === "") {
                    setCalerta04("Calerta04-on");
                    interruptor = false;
                    break;
                } else {
                    setCalerta04("Calerta04-off");
                    interruptor = true;
                }
                if (objContacto.mensaje === "") {
                    setCalerta05("Calerta05-on");
                    interruptor = false;
                    break;
                } else {
                    setCalerta05("Calerta05-off");
                    interruptor = true;
                }
            }
        }
    }

    return (
        <div >
            <section id="Contacto__header">
                <h2>Contacto</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Contacto</li>
                </ul>
            </section>
            <section id="Contacto__section2">
                <div id="Cons2-content1">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d487.6136143321194!2d-77.03488246586949!3d-12.118384459389286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c83d444f9753%3A0x8255648301793661!2sEl%C3%ADas%20Aguirre%20180%2C%20Miraflores%2015074!5e0!3m2!1ses-419!2spe!4v1633542944251!5m2!1ses-419!2spe" width="600" height="350" loading="lazy"></iframe>
                </div>
                <div id="Cons2-content2">
                    <p>Solicitar <strong>Información</strong></p>
                    <div id="Cons2-content2__separator"></div>
                    <div id="Cons2-content2__form">
                        <input type="text" placeholder="Nombres" name="nombres" value={objContacto.nombres} onChange={cargardatos} />
                        <figure id={Calerta01}>
                            <img src={alerta} />
                            <p>Rellene este campo</p>
                        </figure >
                        <input type="text" placeholder="Apellidos" name="apellidos" value={objContacto.apellidos} onChange={cargardatos} />
                        <figure id={Calerta02}>
                            <img src={alerta} />
                            <p>Rellene este campo</p>
                        </figure >
                        <input type="number" placeholder="Telefono" name="telefono" value={objContacto.telefono} onChange={cargardatos} />
                        <figure id={Calerta03}>
                            <img src={alerta} />
                            <p>Rellene este campo</p>
                        </figure >
                        <input type="email" placeholder="Email" name="correo" value={objContacto.correo} onChange={cargardatos} />
                        <figure id={Calerta04}>
                            <img src={alerta} />
                            <p>Rellene este campo</p>
                        </figure >
                        <textarea placeholder="Escriba su mensaje" cols={ 5 } rows={ 3 } name="mensaje" value={objContacto.mensaje} onChange={cargardatos}></textarea>
                        <figure id={Calerta05}>
                            <img src={alerta} />
                            <p>Rellene este campo</p>
                        </figure>
                        <div onClick={validarcontactoM}>ENVIAR</div>
                    </div>
                </div>
            </section>
            <section id="Contacto__section3">
                <div id="Cons3-cont1">
                    <div id="Cons3-con1__c1">
                        <figure>
                            <img src={email} />
                        </figure>
                    </div>
                    <div id="Cons3-con1__c2">
                        <p id="Cons3-cont1__c2--p1">Email</p>
                        <p id="Cons3-cont1__c2--p2">ventas@infra-sol.com</p>
                    </div>
                </div>
                <div id="Cons3-cont2">
                    <div id="Cons3-con2__c1">
                        <figure>
                            <img src={telefono} />
                        </figure>
                    </div>
                    <div id="Cons3-cont2__c2">
                        <p id="Cons3-cont2__c2--p1">Teléfono</p>
                        <p id="Cons3-cont2__c2--p2">Central.</p>
                        <p id="Cons3-cont2__c2--p3">T. +51(1) 233-1927</p>
                    </div>
                </div>
                <div id="Cons3-cont3">
                    <div id="Cons3-con3__c1">
                        <figure>
                            <img src={mapa} />
                        </figure>
                    </div>
                    <div id="Cons3-cont3__c2">
                        <p id="Cons3-cont3__c2--p1">Direccion</p>
                        <p id="Cons3-cont3__c2--p2">Calle Elias Aguirre N° 180.</p>
                        <p id="Cons3-cont3__c2--p3">Miraflores-Lima</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default CMContacto;