export interface ModelHelpdesk {
    empresa: string;
    contacto: string;
    correo: string;
    telefono: string;
    descripcion: string;
    categorias: string,
}

export const newHelpdesk: ModelHelpdesk = {
    empresa: "",
    contacto: "",
    correo: "",
    telefono: "",
    descripcion: "",
    categorias: "Bitdefender Empresas"
};