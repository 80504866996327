import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { openNewTab } from '../../../../utilities/index';
import { URL_INTRANET } from '../../../../constants/public.constant'
import infrasol from '../../../../assets/img/desktop/logo_infrasol.svg';
import flecha from '../../../../assets/img/desktop/arrow.svg';
import './CDHeader.css';

function CDHeader() {

    const navigate = useNavigate();
    const [CDHmostrarmenu, setCDHmostrarmenu] = useState("CDH-no-mostrar-menu");
    const [CDHflecha, setCDHflecha] = useState("CDH-flecha-desactivado");

    function openMenu() {
        if (CDHmostrarmenu === "CDH-mostrar-menu") {
            setCDHmostrarmenu("CDH-no-mostrar-menu");
            setCDHflecha("CDH-flecha-desactivado");
        }
        else {
            setCDHmostrarmenu("CDH-mostrar-menu");
            setCDHflecha("CDH-flecha-activado");
        }
    }

    function loadViewDesktop(name: string) {
        navigate("/" + name)

        if (CDHmostrarmenu === "CDH-mostrar-menu") {
            setCDHmostrarmenu("CDH-no-mostrar-menu");
            setCDHflecha("CDH-flecha-desactivado");
        }
    }

    function abrirIntranet() {
        openNewTab(URL_INTRANET);
    }

    return (
        <header id="CDHeader">
            <div id="CDH-bar">
                <ul className="CDH-bar__lista"  >
                    <li onClick={() => loadViewDesktop("Helpdesk")}>Mesa de Ayuda</li>
                    <li onClick={abrirIntranet}>Intranet</li>
                </ul>
            </div>
            <div id="CDH-content">
                <div id="CDH-content__logo">
                    <img onClick={() => loadViewDesktop("")} src={infrasol} alt="Infrasol SAC" />
                </div>
                <ul className="CDH-content__lista"  >
                    <li onClick={() => loadViewDesktop("")}>INICIO</li>
                    <li onClick={() => loadViewDesktop("Nosotros")}>NOSOTROS</li>
                    <div id="CDH-content__lista--menu" >
                        <div onClick={openMenu} id={CDHflecha}>
                            <p>SERVICIOS</p>
                            <img id="img-flechaheader" src={flecha} alt="Option Infrasol SAC" />
                        </div>
                        <ul id={CDHmostrarmenu}>
                            <li onClick={() => loadViewDesktop("Bitdefender")}>Bitdefender Antivirus</li>
                            <li onClick={() => loadViewDesktop("Kaspersky")}>Kaspersky Antivirus</li>
                            <li onClick={() => loadViewDesktop("Microsoft")}>Licenciamiento Microsoft</li>
                            <li onClick={() => loadViewDesktop("Infraestructura")}>Servicios de Infraestructura</li>
                            <li onClick={() => loadViewDesktop("Networking")} >Soluciones en Networking</li>
                            <li onClick={() => loadViewDesktop("Hardware")}>Distribución de Hardware</li>
                            <li onClick={() => loadViewDesktop("Antispam")} id="antispam">Antispam</li>
                        </ul>
                    </div>
                    <li onClick={() => loadViewDesktop("Contacto")}>CONTACTO</li>
                </ul>
            </div>
        </header>
    );
}

export default CDHeader;