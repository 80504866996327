import React from "react";
import { View } from '../../../components/templates/View';
import { CDInfraestructura, CMInfraestructura } from '../organisms';
import { useViewport } from "../../../hooks";

const Infraestructura = () => {

    const { width } = useViewport();

    return(
        width > 850 ?
            <>
                <View children={ <CDInfraestructura /> }/>
            </>
        :
            <>
                <View children={ <CMInfraestructura/> }/>
            </>
    );
}

export default Infraestructura;