import { useEffect, useState } from "react";
import { ContextViewport } from "../contexts";

export const ProviderViewport = ({ children }: any) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, [])

    return (
        <ContextViewport.Provider value={{ width, height }}>
            { children }
        </ContextViewport.Provider>
    );
}