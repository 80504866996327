import axios from "axios";
import { ModelContacto, ModelHelpdesk } from "../models";
import { API_SOLICITAR_TICKET, API_SOLICITAR_INFORMACION, SUCCESS, ERROR } from "../constants/public.constant";
import { showPopups } from "../utilities";

const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
};

export const SolicitarTicket = (objHelpdesk:ModelHelpdesk) => {

    let objPetition:any = { ...objHelpdesk, telefono: Number.parseInt(objHelpdesk.telefono) }

    return axios.post<ModelHelpdesk>(API_SOLICITAR_TICKET, objPetition, config)
        .then(async () => {
            showPopups("Solicitar Ticket", "Se envio correctamente la solicitud del ticket!", SUCCESS);
            return true;
        })
        .catch(async (error) => {
            let messageError:string = "";

            if (error.response) {
                if (error.response.status.toString().charAt(0) === "4") {
                    messageError = "Ocurrió un error en el envío de la solicitud del ticket, verificar que los datos se hayan ingresado correctamente y vuelva a intentarlo";
                }
            } else if(error.request) {
                messageError = error.request;
            } else {
                messageError = "Ocurrió un error inesperado al enviar la solicitud del ticket, intentelo nuevamente";
            }

            showPopups("Solicitar Ticket", messageError, ERROR)

            return false;
        });
}

export const SolicitarInformacion = (objContacto:ModelContacto) => {

    let objPetition:any = { ...objContacto, telefono: Number.parseInt(objContacto.telefono) }

    return axios.post<ModelHelpdesk>(API_SOLICITAR_INFORMACION, objPetition, config)
        .then(() => {
            showPopups("Solicitud de Información", "Se envió correctamente la solicitud de la información!", SUCCESS);
            return true;
        })
        .catch((error) => {
            let messageError:string = "";

            if (error.response) {
                if (error.response.status.toString().charAt(0) === "4") {
                    messageError = "Ocurrió un error en el envío de la solicitud de información, verificar que los datos se hayan ingresado correctamente y vuelva a intentarlo";
                } else {
                    messageError = "Ocurrió un error inesperado al enviar la solicitud de información, intentelo nuevamente";
                }
            } else if(error.request) {
                messageError = error.request;
            } else {
                messageError = "Ocurrió un error inesperado al enviar la solicitud de información, intentelo nuevamente";
            }

            showPopups("Solicitud de Información", messageError, ERROR)

            return false;
        });
}