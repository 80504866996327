import React from "react";
import { View } from '../../../components/templates/View';
import { CDNetworking, CMNetworking } from '../organisms';
import { useViewport } from "../../../hooks";

const Networking = () => {

    const { width } = useViewport();

    return(
        width > 850 ?
            <>
                <View children={ <CDNetworking /> }/>
            </>
        :
            <>
                <View children={ <CMNetworking/> }/>
            </>
    );
}

export default Networking;