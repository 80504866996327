import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import microsoft1 from '../../../../assets/img/desktop/6.jpg';
import next from '../../../../assets/img/desktop/arrow.svg';
import microsoft2 from '../../../../assets/img/desktop/banner25.jpg';
import carrusel1 from '../../../../assets/img/desktop/bitdefender.jpg';
import bannerbit2 from '../../../../assets/img/desktop/BitdefenderGold.png';
import bannerbit1 from '../../../../assets/img/desktop/Fondblue.png';
import kapersky2 from '../../../../assets/img/desktop/karpesky1.png';
import kapersk3 from '../../../../assets/img/desktop/karpesky2.jpg';
import kapersky1 from '../../../../assets/img/desktop/karpesky3.jpg';
import carrusel2 from '../../../../assets/img/desktop/kaspersky.jpg';
import c1 from '../../../../assets/img/desktop/Bitdefender Antivirus.png';
import c2 from '../../../../assets/img/desktop/Kaspersky Antivirus.png';
import c3 from '../../../../assets/img/desktop/Licenciamiento Microsoft.png';
import c4 from '../../../../assets/img/desktop/Servicios de Infraestructura.png';
import c5 from '../../../../assets/img/desktop/Soluciones de Networking.png';
import c6 from '../../../../assets/img/desktop/Distribucion de Hardware.png';
import c8 from '../../../../assets/img/desktop/Antispam.png';
import carrusel3 from '../../../../assets/img/desktop/microsoft.jpg';
import security1 from '../../../../assets/img/desktop/Servicios-infraestructura.jpg';
import security2 from '../../../../assets/img/desktop/Soluciones-Networking.jpg';
import partner1 from '../../../../assets/img/desktop/Zebra_logo.png';
import partner2 from '../../../../assets/img/desktop/MicrosoftTeams-image.png';
import partner3 from '../../../../assets/img/desktop/Microsoft_logo_(2012).svg.png';
import partner4 from '../../../../assets/img/desktop/Partner4.jpg';
import partner5 from '../../../../assets/img/desktop/Partner6.jpg';
import partner6 from '../../../../assets/img/desktop/Bitdefender-Logo.wine.png';
import partner7 from '../../../../assets/img/desktop/Autodesk_Logo.svg.png';
import partner8 from '../../../../assets/img/desktop/351497.png';
import partner9 from '../../../../assets/img/desktop/350655.png';
import partner10 from '../../../../assets/img/desktop/2048px-HP_New_Logo_2D.svg.png';
import partner11 from '../../../../assets/img/desktop/1280px-Epson_logo.svg.png';
import partner12 from '../../../../assets/img/desktop/1200px-Kaspersky_Lab_logo.svg.png';
import partner13 from '../../../../assets/img/desktop/logo_heimdal_security.png';
import partner14 from '../../../../assets/img/desktop/Lenovo_logo_2015.svg.png';
import partner15 from '../../../../assets/img/desktop/Partner15.jpg';
import partner16 from '../../../../assets/img/desktop/Dell_Logo.svg.png';
import './CDHome.css';

function CDHome() {

    const navigate = useNavigate();
    const [stPartners] = useState([partner1, partner2, partner3, partner4, partner5, partner6, partner7, partner8, partner9, partner10, partner11, partner12, partner13, partner14, partner15, partner16]);

    useEffect(() => {
        let idInterval = setInterval(() => {
            if (window.location.pathname === "/") {
                try {
                    moverCarrusel("derecha");
                    moverCarruselpartner("derecha");
                }  catch(e) {
                    window.location.reload();
                }
                
            } else {
                window.clearInterval(idInterval);
            }
        }, 5000);
    }, []);

    function moverCarrusel(direccion:string) {
        let stContenido:any = document.getElementById("CDH-c1__image");

        if (direccion === "derecha") {
            let itemPrimero = stContenido.childNodes[0];
            stContenido.style.marginLeft = "-200%";
            stContenido.style.transition = "all .5s";

            setTimeout(() => {
                stContenido.style.transition = "none";
                stContenido.insertAdjacentElement('beforeend', itemPrimero);
                stContenido.style.marginLeft = "-100%";
            }, 500);
        }
        else {
            let itemFinal = stContenido.childNodes[stContenido.childNodes.length - 1];
            stContenido.style.marginLeft = "0%";
            stContenido.style.transition = "all 0.5s"

            setTimeout(() => {
                stContenido.style.transition = "none";
                stContenido.insertAdjacentElement('afterbegin', itemFinal);
                stContenido.style.marginLeft = "-100%";
            }, 500);
        }
    }

    function moverCarruselpartner(direccion:string) {
        let stContenidoPartner:any = document.getElementById("CDH-c8Partner");

        if (direccion === "derecha") {
            let itemPrimeropartner = stContenidoPartner.childNodes[0];

            if (window.innerWidth < 1000) {
                stContenidoPartner.style.marginLeft = "-66.66%";
            }
            else {
                stContenidoPartner.style.marginLeft = "-50%";
            }
            stContenidoPartner.style.transition = "all .5s";

            setTimeout(() => {
                stContenidoPartner.style.transition = "none";
                stContenidoPartner.insertAdjacentElement('beforeend', itemPrimeropartner);

                if (window.innerWidth < 1000) {
                    stContenidoPartner.style.marginLeft = "-33.33%";
                }
                else {
                    stContenidoPartner.style.marginLeft = "-25%";
                }
            }, 500);
        }
        else {
            let itempartner = stContenidoPartner.childNodes;
            let itemfinalpartner = itempartner[itempartner.length - 1];

            stContenidoPartner.style.marginLeft = "0%";
            stContenidoPartner.style.transition = "all 0.5s"

            setTimeout(() => {
                stContenidoPartner.style.transition = "none";
                stContenidoPartner.insertAdjacentElement('afterbegin', itemfinalpartner);
                stContenidoPartner.style.marginLeft = "-33.33%";
            }, 500);
        }
    }

    function navigateView(name:string) {
        navigate("/" + name);
    }

    return (
        <main id="CDHome">
            <section id="CDH-c1">
                <ul id="CDH-c1__image">
                    <li id="CDH-c1__image--item">
                        <img src={carrusel3} />
                        <div id="CDH-c1__image--item__text3">
                            <div id="CDH-c1__image--item__text3--i1">
                                <h3>Licenciamiento Microsoft</h3>
                                <p>Adquiera sus licencias Microsoft con nosotros y licencie correctamente.</p>
                            </div>
                            <div id="CDH-c1__image--item__text--boton">
                                <p onClick={() => navigateView("Microsoft")}>Consultar</p>
                            </div>
                        </div>
                    </li>
                    <li id="CDH-c1__image--item">
                        <img src={carrusel1} />
                        <div id="CDH-c1__image--item__text">
                            <div id="CDH-c1__image--item__text--i1">
                                <h3>Bitdefender líder mundial en seguridad informática</h3>
                                <p>Más de 500 millones de sistemas protegidos durante más de 20 años.</p>
                            </div>
                            <div id="CDH-c1__image--item__text--boton" >
                                <p onClick={() => navigateView("Bitdefender")}>Consultar</p>
                            </div>
                        </div>
                    </li>
                    <li id="CDH-c1__image--item">
                        <img src={carrusel2} />
                        <div id="CDH-c1__image--item__text2">
                            <div id="CDH-c1__image--item__text2--i1">
                                <h3>Kaspersky para empresas</h3>
                                <p>Soluciones de verdadera ciberseguridad de última generación
                                    para predecir, evitar y detectar ciberataques, asi como para dar respuesta a ellos.</p>
                            </div>
                            <div id="CDH-c1__image--item__text--boton2">
                                <p onClick={() => navigateView("Kaspersky")}>Consultar</p>
                            </div>
                        </div>
                    </li>
                </ul>
                <div id="CDH-c1__izquierda" onClick={() => moverCarrusel("izquierda")}>
                    <div >
                        <img className="CDH-img-left" src={next} />
                    </div>
                </div>
                <div id="CDH-c1__derecha" onClick={() => moverCarrusel("derecha")}>
                    <div>
                        <img className="CDH-img-right" src={next} />
                    </div>
                </div>
            </section>
            <section id="CDH-c2">
                <div id="CDH-c2__text">
                    <p>SERVICIOS</p>
                    <h3>Soluciones<strong> Tecnológicas</strong></h3>
                </div>
                <ul id="CDH-c2__lista">
                    <li onClick={() => navigateView("Bitdefender")}>
                        <img className="img-check" src={c1} />
                        <p className="p-subtitle">Bitdefender Antivirus</p>
                    </li>
                    <li onClick={() => navigateView("Kaspersky")}>
                        <img className="img-check" src={c2} />
                        <p className="p-subtitle">Kaspersky Antivirus</p>
                    </li>
                    <li onClick={() => navigateView("Microsoft")}>
                        <img className="img-check" src={c3} />
                        <p className="p-subtitle">Licenciamiento Microsoft</p>
                    </li>
                    <li onClick={() => navigateView("Infraestructura")}>
                        <img className="img-check" src={c4} />
                        <p className="p-subtitle">Servicios de Infraestructura</p>
                    </li>
                    <ul id="CDH-c2__lista2">
                        <li onClick={() => navigateView("Networking")}>
                            <img className="img-check" src={c5} />
                            <p className="p-subtitle">Soluciones en Networking</p>
                        </li>
                        <li onClick={() => navigateView("Hardware")}>
                            <img className="img-check" src={c6} />
                            <p className="p-subtitle">Distribucion de Hardware</p>
                        </li>
                        <li onClick={() => navigateView("Antispam")}>
                            <img className="img-check" src={c8} />
                            <p className="p-subtitle">Antispam</p>
                        </li>
                    </ul>
                </ul>
            </section>
            <section id="CDH-c3">
                <figure id="CDH-c3__image">
                    <img src={bannerbit1} />
                </figure>
                <div id="CDH-c3__text">
                    <div id="CDH-c3__text--parrafos">
                        <h1 >DESCUBRA LAS AMENAZAS EVASIVAS Y RESPONDA EFIZCAMENTE</h1>
                        <p>Defienda su organizacion contra las amenazas digitales con capacidades de seguridad avanzadas integradas,
                            a un precio adecuado para su empresa.</p>
                        <p>Somos Partner Gold, confie su seguridad en Bitdefender y proteja sus servidores y estaciones de trabajo.</p>
                    </div>
                    <figure id="CDH-c3__text--image">
                        <img src={bannerbit2} />
                    </figure>
                    <div id="CDH-c3__text--consulta">
                        <p onClick={() => navigateView("Bitdefender")}>Consultar</p>
                    </div>
                </div>
            </section>
            <section id="CDH-c4">
                <div id="CDH-c4__text">
                    <div id="CDH-c4__text--p1">
                        <p>NOSOTROS</p>
                    </div>
                    <div id="CDH-c4__text--p2">
                        <p>Somos un equipo apasionado y<strong> dedicado de asesores y consultores.</strong></p>
                    </div>
                    <div id="CDH-c4__text--p3">
                        <p>Comunicaciones, Infraestructura y Seguridad Informática</p>
                    </div>
                    <div id="CDH-c4__text--p4">
                        <p onClick={() => navigateView("Nosotros")}>Conócenos</p>
                    </div>
                </div>
                <div id="CDH-c4__bar">
                    <ul>
                        <li>
                            <p>Bitdefender Antivirus 95% </p>
                            <div id="bit-95"></div>
                        </li>
                        <li>
                            <p>Kapersky Antivirus 85% </p>
                            <div id="kap-85"></div>
                        </li>
                        <li>
                            <p>Licenciamiento Microsoft 90% </p>
                            <div id="mic-90"></div>
                        </li>
                        <li>
                            <p>Servicios de Infraestructura 85% </p>
                            <div id="inf-85"></div>
                        </li>
                        <li>
                            <p>Distribucion de Hardware 90% </p>
                            <div id="har-90"></div>
                        </li>
                        <li>
                            <p>Soluciones de Networking 90% </p>
                            <div id="net-90"></div>
                        </li>
                    </ul>
                </div>
            </section>
            <section id="CDH-c5">
                <figure id="CDH-c5__fondo">
                    <img src={kapersky1} alt="Kaspersky" />
                </figure>
                <div id="CDH-c5__images">
                    <div id="CDH-c5__images--image1">
                        <figure>
                            <img src={kapersky2} alt="Kaspersky" />
                        </figure>
                        <div >
                            <p onClick={() => navigateView("Kaspersky")}>Consultar</p>
                        </div>
                    </div>
                    <div id="CDH-c5__images--image2">
                        <figure >
                            <img src={kapersk3} alt="Kaspersky" />
                        </figure>
                    </div>
                </div>
            </section>
            <section id="CDH-c6">
                <div id="CDH-c6__item">
                    <img id="CDH-c6__item--image" src={security1} alt="Infrasol Security" />
                    <div id="CDH-c6__item--text">
                        <h3>Servicios de Infraestructura</h3>
                        <p>Servicios integrales de gestión que dan respuesta a las necesidades de la plataforma de TI de las empresas.</p>
                    </div>
                </div>
                <div id="CDH-c6__item2">
                    <img id="CDH-c6__item2--image" src={security2} alt="Infrasol Security" />
                    <div id="CDH-c6__item2--text">
                        <h3>Soluciones en Networking</h3>
                        <p>Ofrece consultoría, gestión de proyectos, planificación, testeo, mantenimiento y soporte en Networking.</p>
                    </div>
                </div>
            </section>
            <section id="CDH-c7">
                <figure id="CDH-c7__image">
                    <img src={microsoft1} alt="Microsoft" />
                </figure>
                <div id="CDH-c7__text">
                    <figure>
                        <img src={microsoft2} alt="Microsoft" />
                    </figure>
                    <div >
                        <p onClick={() => navigateView("Microsoft")}>Consultar</p>
                    </div>
                </div>
            </section>
            <section id="CDH-c8">
                <section>
                    <div id="CDH-c8__partner" >
                        <div id="CDH-c8__partner--text">
                            <h3>Nuestros <strong>Partners.</strong></h3>
                            <div>
                                <ul id="CDH-c8Partner">
                                    {
                                        stPartners.map((item, index) => (
                                            <li key={index} className="CDH-c8__partner--item">
                                                <img src={item} alt="Infrasol Partners" />
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </main>
    );
}

export default CDHome;