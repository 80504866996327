import React from "react";
import { View } from '../../../components/templates/View';
import { CDHome, CMHome } from '../organisms';
import { useViewport } from "../../../hooks";

const Home = () => {

    const { width } = useViewport();

    return (
        width > 850 ?
            <View children={<CDHome />} />
            :
            <View children={<CMHome />} />
    );
}

export default Home;