import React from 'react';
import MainRoutes from './routes/main.routes';
import { ProviderViewport } from './providers';
import './App.css';

function App() {
  return (
    <ProviderViewport>
      <MainRoutes />
    </ProviderViewport>
  );
}

export default App;