import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import infrasol from '../../../../assets/img/movil/logo_infrasol.svg';
import logo_menu from '../../../../assets/img/movil/boton-menu.svg';
import logo_flecha from '../../../../assets/img/movil/arrow.svg';
import './CMHeader.css';

function CMHeader() {

    const navigate = useNavigate();
    const [mostrarmenu, setMostrarmenu] = useState("no-mostrar-menu");
    const [servicios, setServicios] = useState("servicio-desactivado");
    const [flecha, setFlecha] = useState("flecha-desactivado");

    function openMenu() {
        if (mostrarmenu === "mostrar-menu") {
            setMostrarmenu("no-mostrar-menu");
        }
        else {
            setMostrarmenu("mostrar-menu");
        }
    }

    function openMenuServicios() {
        if (servicios === "servicio-desactivado") {
            setServicios("servicio-activado");
            setFlecha("flecha-activado");
        }
        else {
            setServicios("servicio-desactivado");
            setFlecha("flecha-desactivado");
        }
    }

    function loadViewMovil(name: string) {
        navigate("/" + name);
        window.location.reload();

        if (mostrarmenu === "mostrar-menu") {
            setMostrarmenu("no-mostrar-menu");
        }
    }

    return (
        <header className="CMHeader">
            <ul className="CMHeader__nav">
                <li className="CMHeader__nav-help" onClick={() => loadViewMovil("Helpdesk")}>Mesa de Ayuda</li>
                <li>
                    <img id="img-logo" src={infrasol} alt="Infrasol SAC" onClick={() => loadViewMovil("")} />
                </li>
                <li >
                    <img id="img-menu" src={logo_menu} alt="Menu - Infrasol SAC" onClick={openMenu}/>
                </li>
            </ul>
            <div id={mostrarmenu} >
                <ul className="lista__menu">
                    <li onClick={() => loadViewMovil("")}>INICIO </li>
                    <li onClick={() => loadViewMovil("Nosotros")}>NOSOTROS</li>
                    <div className="lista__menu-servicios">
                        <div onClick={openMenuServicios} id={flecha}>
                            <p>SERVICIOS</p>
                            <img id="img-flecha" src={logo_flecha} alt="Options - Infrasol SAC"/>
                        </div>
                        <ul id={servicios}>
                            <li onClick={() => loadViewMovil("Bitdefender")}>Bitdefender Antivirus</li>
                            <li onClick={() => loadViewMovil("Kaspersky")}>Kaspersky Antivirus</li>
                            <li onClick={() => loadViewMovil("Microsoft")}>Licenciamiento Microsoft</li>
                            <li onClick={() => loadViewMovil("Infraestructura")}>Servicios de Infraestructura</li>
                            <li onClick={() => loadViewMovil("Networking")} >Soluciones en Networking</li>
                            <li onClick={() => loadViewMovil("Hardware")}>Distribución de Hardware</li>
                            <li onClick={() => loadViewMovil("Antispam")} id="linea-net">Antispam</li>
                        </ul>
                    </div>
                    <li onClick={() => loadViewMovil("Contacto")}>CONTACTO</li>
                </ul>
            </div>
        </header>
    );
}

export default CMHeader;