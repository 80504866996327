import React from "react";
import { View } from '../../../components/templates/View';
import { CDKaspersky, CMKaspersky } from '../organisms';
import { useViewport } from "../../../hooks";

const Kaspersky = () => {

    const { width } = useViewport();

    return(
        width > 850 ?
            <>
                <View children={ <CDKaspersky /> }/>
            </>
        :
            <>
                <View children={ <CMKaspersky/> }/>
            </>
    );
}

export default Kaspersky;