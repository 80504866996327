import React from 'react';
import { useNavigate } from 'react-router-dom';
import mic01 from '../../../../assets/img/desktop/licencias.jpg';
import mic02 from '../../../../assets/img/desktop/pc.jpg';
import mic03 from '../../../../assets/img/desktop/banner_licencia.jpg';
import mic04 from '../../../../assets/img/desktop/Windows10_Footer_Icon_MSStore.png';
import mic05 from '../../../../assets/img/desktop/Windows10_Footer_Icon_Skype.png';
import mic06 from '../../../../assets/img/desktop/Logo_Project_108x40.png';
import mic07 from '../../../../assets/img/desktop/iconoSharePoint.png';
import mic08 from '../../../../assets/img/desktop/Logo_Visio_White_90x40.png';
import mic09 from '../../../../assets/img/desktop/Windows10_Footer_Icon_Office.png';
import mic10 from '../../../../assets/img/desktop/cintillonube.jpg';
import mic11 from '../../../../assets/img/desktop/365.png';
import mic12 from '../../../../assets/img/desktop/azure.png';
import mic13 from '../../../../assets/img/desktop/microsoft-sql-server-logo.png';
import mic14 from '../../../../assets/img/desktop/visual-studio-logo.png';
import './CDMicrosoft.css';

function CDMicrosoft() {

    const navigate = useNavigate();

    function navigateView(name:string) {
        navigate("/" + name);
    }

    return (
        <main id="CDMicrosoft">
            <section id="CDM-c1">
                <h2>Licenciamiento Microsoft</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Servicios</li>
                    <li>/</li>
                    <li>Licenciamiento Microsoft</li>
                </ul>
            </section>
            <section id="CDM-c2">
                <figure id="CDM-c2__image">
                    <img src={mic01} />
                </figure>
                <div id="CDM-c2__text">
                    <h4>Licenciamiento<strong> Open</strong></h4>
                    <p className="text-p1">Adquiere licencias originales Microsoft y ayuda a que tu organización siga siendo competitiva.</p>
                    <p className="text-p2">¡Utiliza licencias originales y aumenta la productividad de tu organización!</p>
                    <p className="text-p3">Como Socio de Negocios de Microsoft te ofrecemos toda la gama de soluciones de productividad de oficina, desarrollo de software de Microsoft.</p>
                </div>
            </section>
            <section id="CDM-c3">
                <div id="CDM-c3__item1">
                    <div id="CDM-c3__item1--image">
                        <figure>
                            <img src={mic02} />
                        </figure>
                    </div>
                    <div id="CDM-c3__item1--text">
                        <h2>Adquiere Windows!</h2>
                        <p>El sistema operativo más popular para tus equipos, dispositivos y servidores. Te asesoramos en cuanto a la mejor configuración de acuerdo al tamaño y sector
                            de tu organización.</p>
                    </div>
                </div>
                <div id="CDM-c3__item2">
                    <div id="CDM-c3__item2--text">
                        <h2>Windows 10</h2>
                        <div></div>
                        <p >Utiliza en tus estaciones de trabajo y dispositivos el sistema operativo más popular y familiar del mercado.</p>
                        <p >El nuevo sistema operativo de Microsoft trae características tanto de Windows 8 como de Windows 7 con su botón de inicio, se inicia rápidamente y
                            cuenta con mejores características de seguridad y lo mejor: <strong>funciona con el software y hardware que posees.</strong></p>
                        <p >Actualiza Windows 10 Gratis</p>
                    </div>
                </div>
                <div id="CDM-c3__item3">
                    <div id="CDM-c3__item3--text">
                        <h2>Windows Server</h2>
                        <div></div>
                        <p>Mejora tu servicio de red y disponibilidad de tus aplicaciones con <strong>Windows Server 2019 R2.</strong>Esta nueva versión potencia la prestación de servicios
                        en la nube en una infraestructura junto con las nuevas características en cuanto a virtualización, mejor administración, optimización del almacenamiento,
                            las redes, la infraestructura del escritorio virtual (VDI), mayor seguridad de la información y en cuanto al acceso a la plataforma de sus aplicaciones y web.</p>
                    </div>
                </div>
            </section>
            <section id="CDM-c4">
                <figure id="CDM-c4__image">
                    <img src={mic03} />
                </figure>
                <div id="CDM-c4__text">
                    <h4>Productividad de Oficinas</h4>
                    <p>Provee a tus empleados de herramientas que incrementen su productividad.</p>
                    <p >Te ayudamos a entender cómo utilizarlas para sacarle el máximo provecho de ella.</p>
                    <div>
                        <p onClick={() => navigateView("Contacto")}>Solicitar Cotización</p>
                    </div>
                </div>
            </section>
            <section id="CDM-c5">
                <div>
                    <ul className="CDM-c5__lista">
                        <li className="CDM-c5__lista--item">
                            <div className="CDM-c5__lista--item_image">
                                <figure>
                                    <img src={mic04} />
                                </figure>
                                <p>Exchange</p>
                            </div>
                            <div className="CDM-c5__lista--item_text">
                                <p>Incrementa tu productividad, utiliza tu correo empresarial de manera segura desde cualquier lugar. Ponemos a tu dispocisión Exchange Server 2019
                                para que administres tus propios servidores de correo o si no dispones del hardware requerido, te ofrecemos Exchange Online el nuevo servicio hospedado
                                    de Microsoft.</p>
                            </div>
                        </li>
                        <li className="CDM-c5__lista--item">
                            <div className=" CDM-c5__lista--item_image">
                                <figure>
                                    <img src={mic05} />
                                </figure>
                                <p>Skype Empresarial</p>
                            </div>
                            <div className="CDM-c5__lista--item_text">
                                <p>Con Skype Empresarial comparte de manera segura mensajería, audio y videollamadas, reuniones en linea y más. Puedes reunirte hasta con 250 personas
                                aunque éstas no formen parte de Skype Empresarial. Esta versión se integra perfectamente con Office lo cual te permite iniciar cualquier conversación
                                desde estas aplicaciones.
                                </p>
                            </div>
                        </li>
                        <li className="CDM-c5__lista--item">
                            <div className=" CDM-c5__lista--item_image">
                                <figure>
                                    <img src={mic06} />
                                </figure>
                                <p>Project</p>
                            </div>
                            <div className="CDM-c5__lista--item_text">
                                <p>Haz un control eficiente de tus proyectos y de los recursos asignados a los mismos desde una misma aplicación con Project 2019,
                                    entrega a tiempo tus reportes de estatus de actividades y tareas gracias a la información que puedes gestionar desde esta aplicación.</p>
                            </div>
                        </li>
                    </ul>
                    <ul className="CDM-c5__lista">
                        <li className="CDM-c5__lista--item">
                            <div className=" CDM-c5__lista--item_image">
                                <figure>
                                    <img src={mic07} />
                                </figure>
                                <p>SharePoint</p>
                            </div>
                            <div className="CDM-c5__lista--item_text">
                                <p>Ofrecemos licencias tanto de SharePoint 2019 para ser instalado en un servidor propio como el servicio SharePoint Online,
                                el cual se ofrece junto a Office 365 y ofrece una tecnología de seguridad mejorada, es fácil de administrar y se accede desde cualquier lugar.
                                    Igualmente puedes integrar SharePoint con Yammer para incrementar el ambiente colaborativo entre tus empleados a través de esta red social empresarial.</p>
                            </div>
                        </li>
                        <li className="CDM-c5__lista--item">
                            <div className=" CDM-c5__lista--item_image">
                                <figure>
                                    <img src={mic08} />
                                </figure>
                                <p>Visio</p>
                            </div>
                            <div className="CDM-c5__lista--item_text">
                                <p>Con Visio Professional 2019 puedes elaborar diagramas profesionales a objeto de simplificar información compleja con formas actualizadas,
                                    herramientas de colaboración y diagramas vinculados a datos.</p>
                            </div>
                        </li>
                        <li className="CDM-c5__lista--item">
                            <div className=" CDM-c5__lista--item_image">
                                <figure>
                                    <img src={mic09} />
                                </figure>
                                <p>Office</p>
                            </div>
                            <div className="CDM-c5__lista--item_text">
                                <p>Trabaja todas tus aplicaciones de siempre de manera unificada con Office Professional 2019, bien sea desde tu equipo Windows o
                                desde tu Mac. Esta suite de aplicaciones incluye: Word 2019, Excel 2019, PowerPoint 2019, OneNote 2019, Outlook 2019, Publisher 2019 y Access 2019,
                                las cuales como aplicaciones nativas te permiten incrustar contenidos de una aplicación a otra sin ningún inconveniente y sacarles el mayor provecho a
                                    cada una.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section id="CDM-c6">
                <figure id="CDM-c6__image">
                    <img src={mic10} />
                </figure>
                <div id="CDM-c6__text">
                    <p>Reduce tus costos de infraestructura, personal y equipamiento</p>
                    <p>¡INVIERTE EN LA NUBE!</p>
                </div>
            </section>
            <section id="CDM-c7">
                <div id="CDM-c7__office">
                    <h4>Office 365</h4>
                    <div></div>
                    <figure>
                        <img src={mic11} />
                    </figure>
                    <p>Con office 365 impulsas la productividad en tu empresa, gracias a a que todo lo que se puede hacer en la oficina, ahora también se puede hacer fuera de esta.
                        Herramientas flexibles que se ajustan a las necesidades de los empleadosde hoy en día para que su grado de satisfacción aumente.</p>
                </div>
                <div id="CDM-c7__azure">
                    <h4>Microsoft Azure</h4>
                    <div></div>
                    <figure>
                        <img src={mic12} />
                    </figure>
                    <p><strong>Azure</strong> es la <strong>plataforma de informática en la nube de Microsoft,</strong>una colección cada vez mayor de servicios integrados (análisis, proceso, base de datos, móviles,
                        redes, almacenamiento y web) para moverse con más rapidez, llegar más lejos y ahorrar dinero.</p>
                    <p>Microsoft Azure es una <strong>plataforma abierta y flexible</strong>que le permite construir rápidamente, implementar y administrar aplicaciones a través de una red global
                    de centros de datos gestionados por Microsoft. Integra tus aplicaciones de nube pública con tu entorno de TI actual y genera aplicaciones utilizando cualquier
                        idioma, herramienta o entorno de desarrollo.</p>
                </div>
            </section>
            <section id="CDM-c8">
                <figure id="CDM-c8__image">
                    <img src={mic10} />
                </figure>
                <div id="CDM-c8__text">
                    <p>Para tu equipo de Desarrollo de Software</p>
                    <p>Entornos de desarrollo de aplicaciones e interfaces amigables y fáciles de utilizar</p>
                </div>
            </section>
            <section id="CDM-c9">
                <div id="CDM-c9__sql">
                    <h4>SQL Server</h4>
                    <div></div>
                    <figure>
                        <img src={mic13} />
                    </figure>
                    <p>SQL Server 2014 ofrece un rendimiento excepcional con la tecnología in-memory integrada en la base de datos para realizar transacciones, consultas y análisis, y
                    permite obtener más rápidamente insights de los datos mediante herramientas de análisis conocidas y soluciones Big Data listas para la empresa. Un modelo de
                        programación coherente y herramientas comunes tanto en entornos locales como en la nube admite infraestructuras y escenarios híbridos..</p>
                </div>
                <div id="CDM-c9__visual">
                    <h4>Visual Studio</h4>
                    <div></div>
                    <figure>
                        <img src={mic14} />
                    </figure>
                    <p>Un completo <strong>entorno de desarrollo</strong> integrado para crear aplicaciones eficientes para Windows, Android e iOS,
                        además de aplicaciones web y servicios de nube innovadores.</p>
                </div>
            </section>
        </main>
    );
}

export default CDMicrosoft;