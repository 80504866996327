import React from 'react';
import hard1 from '../../../../assets/img/movil/hardware.jpg';
import hard2 from '../../../../assets/img/movil/Nuestros_Partners.jpg';
import './CMHardware.css';

function CMHardware() {

    return (
        <div id="CMHardware">
            <section id="Hard__head">
                <h2>Distribución de Hardware</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Servicios</li>
                    <li>/</li>
                    <li>Distribución de Hardware</li>
                </ul>
            </section>
            <section id="Hard__section2">
                <figure id="Hard-content">
                    <img src={hard1} />
                </figure>
                <div id="Hard-text">
                    <p id="Hard-text__p1">En <strong>INFRASOL</strong> somos distribuidores oficiales de las <strong>mejores</strong> marcas.</p>
                    <p id="Hard-text__p2">Proveemos las mejores marcas de hardware que existen en el mercado, lo que permite servir a nuestros clientes con equipamiento de alta calidad,
                        rendimiento y garantía: como servidores, puestos de trabajo, periféricos, suministros, etc..</p>
                </div>
            </section>
            <section id="Hard__section3">
                <div id="Hards3-content1">
                    <h2>Hardware de acuerdo a los <strong>tiempos actuales.</strong></h2>
                    <p>La evolución en las empresas exige una renovación constante de los parques informáticos y de los sistemas de comunicación de las organizaciones.
                    En INFRASOL analizamos y estudiamos las herramientas de hardware, software y las metodologías de trabajo más productivas – rentables para asesorar y
                    ayudar a las organizaciones, con el fin de garantizar que se trabaja con productos de hardware y software contrastados, compatibles, escalables y de acuerdo
                        a los tiempos actuales.</p>
                </div>
                <div id="Hards3-content2">
                    <figure>
                        <img src={hard2} />
                    </figure>
                    <p>Cualquier solicitud de cotización no dude en contactarnos en el botón WhatsApp o a través del menú CONTACTO.</p>
                </div>
            </section>
        </div>
    );
}

export default CMHardware;