import React from 'react';
import { useNavigate } from 'react-router-dom';
import map from '../../../../assets/img/movil/map.svg';
import './CMFooter.css';

function CMFooter() {

    const navigate = useNavigate(); 

    function loadViewMovil(name: string) {
        navigate("/" + name);
        window.location.reload();
    }

    return (
        <footer id="footer">
            <div id="footer-info">
                <div id="footer-info__div1">
                    <p >NUESTRA OFICINA</p>
                    <div id="footer-ubicacion">
                        <div id="footer-ubicacion__div1">
                            <figure>
                                <img src={map} alt="Ubication - Infrasol SAC"/>
                            </figure>
                        </div>
                        <div id="footer-ubicacion__div2">
                            <p id="footer-ubicacion__div2--p1">Lima</p>
                            <p id="footer-ubicacion__div2--p2">Calle Elías Aguirre Nº 180 Miraflores</p>
                            <p id="footer-ubicacion__div2--p3">Central T. +51(1) 739-2588</p>
                        </div>
                    </div>
                </div>
                <div id="footer-info-div2">
                    <p id="footer-info-div2--p1">Informes y Ventas</p>
                    <p id="footer-info-div2--p2">ventas@infra-sol.com</p>
                </div>
                <div id="footer-info__div3">
                    <p>SERVICIOS</p>
                    <ul id="footer-info__div3--items">
                        <li onClick={() => loadViewMovil("Bitdefender")} className="footer-li">Bitdefender Antivirus</li>
                        <li onClick={() => loadViewMovil("Kaspersky")} className="footer-linea">Kaspersky Antivirus</li>
                        <li onClick={() => loadViewMovil("Microsoft")} className="footer-linea">Licenciamiento Microsoft</li>
                        <li onClick={() => loadViewMovil("Infraestructura")} className="footer-linea">Servicios de Infraestructura</li>
                        <li onClick={() => loadViewMovil("Networking")} className="footer-linea">Soluciones en Networking</li>
                        <li onClick={() => loadViewMovil("Hardware")} className="footer-linea">Distribución de Hardware</li>
                        <li onClick={() => loadViewMovil("Antispam")} className="footer-linea">Antispam</li>
                    </ul>
                </div>
            </div>
            <div id="footer-bar">
                <p>© Infrasol - Todos los Derechos Reservados</p>
            </div>
        </footer>
    );
}

export default CMFooter;