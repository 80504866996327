import React from "react";
import { View } from '../../../components/templates/View';
import { CDMicrosoft, CMMicrosoft } from '../organisms';
import { useViewport } from "../../../hooks";

const Microsoft = () => {

    const { width } = useViewport();

    return(
        width > 850 ?
            <>
                <View children={ <CDMicrosoft /> }/>
            </>
        :
            <>
                <View children={ <CMMicrosoft/> }/>
            </>
    );
}

export default Microsoft;