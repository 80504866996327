import React from "react";
import { View } from '../../../components/templates/View';
import { CDAntispan, CMAntispan } from '../organisms';
import { useViewport } from "../../../hooks";

const Antispam = () => {

    const { width } = useViewport();

    return(
        width > 850 ?
            <>
                <View children={ <CDAntispan />} />
            </>
        :
            <>
                <View children={ <CMAntispan />} />
            </>
    );
}

export default Antispam;