import React from 'react';
import anti1 from '../../../../assets/img/movil/oem-header-bg-email-md_2x.jpg';
import anti2 from '../../../../assets/img/movil/info-zone-gz.png';
import anti3 from '../../../../assets/img/movil/gz-privacy-icon.svg';
import anti4 from '../../../../assets/img/movil/gz-security-icon.svg';
import anti5 from '../../../../assets/img/movil/gz-radar-icon.svg';
import anti6 from '../../../../assets/img/movil/gz-ftr-bg-md_2x.jpg';
import anti7 from '../../../../assets/img/movil/gz-features-graphics.png';
import anti8 from '../../../../assets/img/movil/msp-email-security_2x.jpg';
import anti9 from '../../../../assets/img/movil/email-security-arhitecture-en_2x.png';
import './CMAntispan.css';

function CMAntispan() {

    return (
        <div id="CMAntispan">
            <section id="Ant__head">
                <h2>AntiSpam</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Servicios</li>
                    <li>/</li>
                    <li>AntiSpam</li>
                </ul>
            </section>
            <section id="Ant__section2">
                <figure id="Ants2-content">
                    <img src={anti1} />
                </figure>
                <div id="Ants2-text">
                    <h1 id="Ants2-text__p1">GRAVITYZONE EMAIL SECURITY</h1>
                    <p id="Ants2-text__p2">Seguridad multicapa de correo electrónico <strong>basada en la nube</strong> para organizaciones y MSP.</p>
                    <p id="Ants2-text__p3">Ofrece prevención esencial de amenazas para las implementaciones estándar de Office 365.</p>
                </div>
            </section>
            <section id="Ant__section3">
                <div id="Ants3-content1">
                    <p id="Ants3-content1__p1">Anticípese al fraude por correo electrónico</p>
                    <p id="Ants3-content1__p2">Con GravityZone Email Security, las organizaciones se benefician de una protección total del correo electrónico empresarial que va más allá del malware y
                    otras amenazas tradicionales como el spam, los virus, los ataques de phishing a gran escala y las URL maliciosas. También DETIENE las amenazas de correo
                        electrónico modernas, selectivas y sofisticadas, incluyendo las de compromiso del correo electrónico empresarial o fraude del CEO.</p>
                </div>
                <div id="Ants3-content2">
                    <div id="Ants3-content2__image">
                        <figure>
                            <img src={anti2} />
                        </figure>
                    </div>
                    <ul id="Ants3-content2__lista">
                        <li className="Ants3-content2__lista--item">
                            <figure id="figure-i1">
                                <img src={anti3} />
                            </figure>
                            <p>Protección incomparable contra amenazas</p>
                        </li>
                        <li className="Ants3-content2__lista--item">
                            <figure id="figure-i2">
                                <img src={anti4} />
                            </figure>
                            <p>Protección contra el compromiso del correo electrónico empresarial o el fraude del CEO</p>
                        </li>
                        <li className="Ants3-content2__lista--item">
                            <figure id="figure-i3">
                                <img src={anti5} />
                            </figure>
                            <p>Varios motores de análisis</p>
                        </li>
                     </ul>
                    
                </div>
            </section>
            <section id="Ant__section4">
                <figure id="Ants4-content">
                    <img src={anti6} />
                </figure>
                <div id="Ants4-content2">
                    <h1 id="Ants4-content2__i1">Características de Bitdefender Network Traffic Security Analytics</h1>
                    <div id="Ansts4-content2__i2">
                        <figure id="Ansts4-content__i2--figure">
                            <img src={anti7} />
                        </figure>
                        <ul id="Ansts4-content__i2--lista1">
                            <li id="lista1-li1">
                                Control total sobre el flujo de correo
                            </li>
                            <li id="lista1-li2">Visibilidad integral, protección de datos y cumplimiento</li>
                            <li id="lista1-li3">Más de 10 000 algoritmos</li>
                        </ul>
                        <ul id="Ansts4-content__i2--lista2">
                            <li id="lista2-li1">Protección en el momento del clic</li>
                            <li id="lista2-li2">Filtrado de salida</li>
                            <li id="lista2-li3">Motor avanzado de enrutamiento de correo</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="Ant__section5">
                <div id="Ants5-content1">
                    <p id="Ants5-content1__p1">Seguridad del correo electrónico para MSP</p>
                    <p id="Ants5-content1__p2">Bitdefender GravityZone Email Security está disponible para proveedores de servicios administrados con multihuésped y licencias mensuales
                    simples basadas en informes de uso automáticos. Esto permite a los MSP racionalizar y automatizar la administración, el aprovisionamiento y las licencias de
                        proveedores y usuarios y consolidar la seguridad del correo electrónico y la protección de los endpoints dentro de la Bitdefender MSP Security Suite.</p>
                </div>
                <div id="Ants5-content2">
                    <figure>
                        <img src={anti8} />
                    </figure>
                </div>
            </section>
            <section id="Ant__section6">
                <p>Arquitectura de seguridad del correo electrónico</p>
                <figure>
                    <img src={anti9} />
                </figure>
            </section>
        </div>
    );
}

export default CMAntispan;