import React from "react";
import { View } from '../../../components/templates/View';
import { CDBitdefender, CMBitdefender } from '../organisms';
import { useViewport } from "../../../hooks";

const Bitdefender = () => {

    const { width } = useViewport();

    return(
        width > 850 ?
            <>
                <View children={ <CDBitdefender />} />
            </>
        :
            <>
                <View children={ <CMBitdefender />} />
            </>
    );
}

export default Bitdefender;