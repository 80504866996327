import React from 'react';
import anti1 from '../../../../assets/img/desktop/oem-header-bg-email-md_2x.jpg';
import anti2 from '../../../../assets/img/desktop/info-zone-gz.png';
import anti3 from '../../../../assets/img/desktop/gz-privacy-icon.svg';
import anti4 from '../../../../assets/img/desktop/gz-security-icon.svg';
import anti5 from '../../../../assets/img/desktop/gz-radar-icon.svg';
import anti6 from '../../../../assets/img/desktop/gz-ftr-bg-md_2x.jpg';
import anti7 from '../../../../assets/img/desktop/gz-features-graphics.png';
import anti8 from '../../../../assets/img/desktop/msp-email-security_2x.jpg';
import anti9 from '../../../../assets/img/desktop/email-security-arhitecture-en_2x.png';
import './CDAntispan.css';

function CDAntispam() {
    
    return (
        <main id="CDAntispam">
            <section id="CDA-c1">
                <h2>Antispam</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Servicios</li>
                    <li>/</li>
                    <li>Antispam</li>
                </ul>
            </section>
            <section id="CDA-c2">
                <figure id="CDA-c2__image">
                    <img src={anti1} />
                </figure>
                <div id="CDA-c2__text">
                    <h1>GRAVITYZONE EMAIL SECURITY</h1>
                    <p>Seguridad multicapa de correo electrónico <strong>basada en la nube</strong> para organizaciones y MSP.</p>
                    <p>Ofrece prevención esencial de amenazas para las implementaciones estándar de Office 365.</p>
                </div>
            </section>
            <section id="CDA-c3">
                <div id="CDA-c3__text">
                    <div id="CDA-c3__text__parrafos">
                        <h4>Anticípese al fraude por correo electrónico</h4>
                        <p>Con GravityZone Email Security, las organizaciones se benefician de una protección total del correo electrónico empresarial que va más allá del malware y
                            otras amenazas tradicionales como el spam, los virus, los ataques de phishing a gran escala y las URL maliciosas. También DETIENE las amenazas de correo
                            electrónico modernas, selectivas y sofisticadas, incluyendo las de compromiso del correo electrónico empresarial o fraude del CEO.</p>
                    </div>
                    <div id="CDA-c3__text__image">
                        <figure>
                            <img src={anti2} />
                        </figure>
                    </div>
                </div>
                <div id="CDA-c3__images">
                    <ul id="CDA-c3__images__options">
                        <li className="CDA-c3__images__options--item">
                            <figure>
                                <img src={anti3} />
                            </figure>
                            <p>Protección incomparable contra amenazas</p>
                        </li>
                        <li className="CDA-c3__images__options--item">
                            <figure id="item-anti4">
                                <img src={anti4} />
                            </figure>
                            <p>Protección contra el compromiso del correo electrónico empresarial o el fraude del CEO</p>
                        </li>
                        <li className="CDA-c3__images__options--item">
                            <figure id="item-anti5">
                                <img src={anti5} />
                            </figure>
                            <p>Varios motores de análisis</p>
                        </li>
                    </ul>
                </div>
            </section>
            <section id="CDA-c4">
                <figure id="CDA-c4__image">
                    <img src={anti6} />
                </figure>
                <div id="CDA-c4__text">
                    <h1>Características de Bitdefender Network Traffic Security Analytics</h1>
                    <div id="CDA-c4__items">
                        <ul >
                            <li>Control total sobre el flujo de correo</li>
                            <li>Visibilidad integral, protección de datos y cumplimiento</li>
                            <li>Más de 10 000 algoritmos</li>
                        </ul>
                        <figure>
                            <img src={anti7} />
                        </figure>
                        <ul>
                            <li>Protección en el momento del clic</li>
                            <li>Filtrado de salida</li>
                            <li>Motor avanzado de enrutamiento de correo</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="CDA-c5">
                <div id="CDA-c5__text">
                    <h4>Seguridad del correo electrónico para MSP</h4>
                    <p>Bitdefender GravityZone Email Security está disponible para proveedores de servicios administrados con multihuésped y licencias mensuales
                        simples basadas en informes de uso automáticos. Esto permite a los MSP racionalizar y automatizar la administración, el aprovisionamiento y las licencias de
                        proveedores y usuarios y consolidar la seguridad del correo electrónico y la protección de los endpoints dentro de la Bitdefender MSP Security Suite.</p>
                </div>
                <div id="CDA-c5__image">
                    <figure>
                        <img src={anti8} />
                    </figure>
                </div>
            </section>
            <section id="CDA-c6">
                <p>Arquitectura de seguridad del correo electrónico</p>
                <figure>
                    <img src={anti9} />
                </figure>
            </section>
        </main>
    );
}

export default CDAntispam;