import React from 'react';
import hard1 from '../../../../assets/img/desktop/hardware.jpg';
import hard2 from '../../../../assets/img/desktop/Nuestros_Partners.jpg';
import './CDHardware.css';

function CDHardware() {

    return (
        <main id="CDHardware">
            <section id="CDHW-c1">
                <h2>Distribución de Hardware</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Servicios</li>
                    <li>/</li>
                    <li>Distribución de Hardware</li>
                </ul>
            </section>
            <section id="CDHW-c2">
                <figure id="CDHW-c2__image">
                    <img src={hard1} />
                </figure>
                <div id="CDHW-c2__text">
                    <h4>En <strong>INFRASOL</strong> somos distribuidores oficiales de las <strong>mejores</strong> marcas.</h4>
                    <p>Proveemos las mejores marcas de hardware que existen en el mercado, lo que permite servir a nuestros clientes con equipamiento de alta calidad,
                        rendimiento y garantía: como servidores, puestos de trabajo, periféricos, suministros, etc..</p>
                </div>
            </section>
            <section id="CDHW-c3">
                <div id="CDHW-c3__text">
                    <h2>Hardware de acuerdo a los <strong>tiempos actuales.</strong></h2>
                    <p>La evolución en las empresas exige una renovación constante de los parques informáticos y de los sistemas de comunicación de las organizaciones.
                    En INFRASOL analizamos y estudiamos las herramientas de hardware, software y las metodologías de trabajo más productivas – rentables para asesorar y
                    ayudar a las organizaciones, con el fin de garantizar que se trabaja con productos de hardware y software contrastados, compatibles, escalables y de acuerdo
                        a los tiempos actuales.</p>
                </div>
                <div id="CDHW-c3__image">
                    <figure>
                        <img src={hard2} />
                    </figure>
                    <p>Cualquier solicitud de cotización no dude en contactarnos en el botón WhatsApp o a través del menú CONTACTO.</p>
                </div>
            </section>
        </main>
    );
}

export default CDHardware;