import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { openNewTab } from '../../../../utilities/index';
import { API_WHATSAPP } from '../../../../constants/public.constant';
import { ButtonMessenger } from '../../../molecules';
import './CMSocial.css';

function CMSocial() {

    function consultarWhatsapp() {
        openNewTab(API_WHATSAPP);
    }

    return(
        <div id="CMSocial">
            <div id="CMFacebook">
                <ButtonMessenger />
            </div>

            <div id="CMWhatsapp" onClick={consultarWhatsapp}>
                <FontAwesomeIcon icon={faWhatsapp} />
            </div>
        </div>
    );
}

export default CMSocial;