import solucion1 from '../../../../assets/img/movil/soluciones.jpg';
import './CMNetworking.css';

function CMNetworking() {

    return (
        <div id="CMNetworking">
            <section id="Net__head">
                <h2>Soluciones en Networking</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Servicios</li>
                    <li>/</li>
                    <li>Soluciones en Networking</li>
                </ul>
            </section>
            <section id="Net__section2">
                <figure id="Net-content">
                    <img src={solucion1}  alt="Soluciones Infrasol"/>
                </figure>
                <div id="Net-text">
                    <p id="Net-text__p1">Contamos con ingenieros especializados<strong> en atender sus proyectos.</strong></p>
                    <p id="Net-text__p2">Diseño, suministro e instalación de redes cableadas e inalámbricas, INFRASOL ofrece consultoría, gestión de proyectos,
                        planificación, testeo, mantenimiento y soporte en Networking.</p>
                </div>
            </section>
            <section id="Net__section3">
                <div id="Nets3-content1">
                    <h2>Comunicaciones IP, Telefonía IP</h2>
                    <div></div>
                    <ul>
                        <li><strong>Comunicaciones IP (VoIP): </strong>Facilitamos la combinación de datos, voz y vídeo en una sola red segura. Proporcionamos tecnología y aplicaciones
                        fáciles de usar que ayudan a minimizar la complejidad, aumentar la eficiencia y permiten adaptarse con rapidez.Reduciendo considerablemente
                            al presupuesto correspondiente al servicio contratado.</li>
                        <li><strong>Telefonía IP: </strong>Esta solución permite transmitir comunicaciones de voz sobre una red de datos basada en el estándar IP. Con la solución de Telefonía IP,
                        la organización reduce costos integrando sus aplicaciones de voz y datos sobre una única plataforma de red, permitiendo brindar servicios transmitidos por
                        redes de datos. Las soluciones de Telefonía IP comprenden gateways de voz H.323, SIP, así como teléfonos de distintas características que ofrecen una gran
                            flexibilidad. Esta solución permite hacer uso de todas las ventajas de mensajería e integración con sus sistemas de telefonía tradicional.</li>
                    </ul>
                </div>
                <div id="Nets3-content2">
                    <h2>Routing, Switching y Wireless</h2>
                    <div></div>
                    <ul>
                        <li><strong>Routing y Switching: </strong>Contamos con los recursos y la experiencia necesaria para poder vender, diseñar, instalar y brindar soporte para el
                            núcleo de la infraestructura de red de pequeñas y medianas empresas. Solucionamos problemas en redes de área extendida (WAN) o de acceso remoto.
                            Estas redes utilizan tecnología Catalyst sobre Ethernet de alta velocidad y routers conectados en redes LAN y WAN..</li>
                        <li><strong>Acceso Inalámbrico: </strong> Soluciones de conectividad inalámbrica con dispositivos como Access Points, Bridges inalámbricos, tarjetas de conexión,
                            Software de gestión que permiten enlaces de distintas velocidades, de distintos alcances según los requerimientos del usuario.</li>
                        <li><strong>Acceso Inalámbrico local: </strong> Seguridad y calidad de funcionamiento para apoyar la movilidad de voz, vídeo y datos, con
                            disponemos de Access Point, Router, adaptadores, seguridad HotSpot, etc.</li>
                        <li><strong>Acceso Inalámbrico remoto: </strong>  Soluciones de soporte para banda ancha inalámbricos para compañías de telecomunicaciones,
                            ISP (proveedores de servicios de Internet) y operadores de redes privadas</li>
                    </ul>
                </div>
            </section>
        </div>
    );
}

export default CMNetworking;