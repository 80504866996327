import { useEffect, useState } from 'react';
import partner1 from '../../../../assets/img/movil/Zebra_logo.png';
import partner2 from '../../../../assets/img/movil/MicrosoftTeams-image.png';
import partner3 from '../../../../assets/img/movil/Microsoft_logo_(2012).svg.png';
import partner4 from '../../../../assets/img/movil/Partner4.jpg';
import partner5 from '../../../../assets/img/movil/Partner5.jpg';
import partner6 from '../../../../assets/img/movil/Bitdefender-Logo.wine.png';
import partner7 from '../../../../assets/img/movil/Autodesk_Logo.svg.png';
import partner8 from '../../../../assets/img/movil/351497.png';
import partner9 from '../../../../assets/img/movil/350655.png';
import partner10 from '../../../../assets/img/movil/2048px-HP_New_Logo_2D.svg.png';
import partner11 from '../../../../assets/img/movil/1280px-Epson_logo.svg.png';
import partner12 from '../../../../assets/img/movil/1200px-Kaspersky_Lab_logo.svg.png';
import partner13 from '../../../../assets/img/movil/logo_heimdal_security.png';
import partner14 from '../../../../assets/img/movil/Lenovo_logo_2015.svg.png';
import partner15 from '../../../../assets/img/movil/Partner15.jpg';
import partner16 from '../../../../assets/img/movil/Dell_Logo.svg.png';
import './CMNosotros.css';

function CMNosotros() {
    const [stPartners] = useState([partner1, partner2, partner3, partner4, partner5, partner6, partner7, partner8, partner9, partner10, partner11, partner12, partner13, partner14, partner15, partner16]);

    useEffect(() => {
        let idInterval = setInterval(() => {
            if (window.location.pathname === "/Nosotros") {
                try {
                    movercarruselpartner("derecha");
                } catch (e) {
                    window.location.reload();
                }

            } else {
                window.clearInterval(idInterval);
            }
        }, 5000);
    }, []);

    function movercarruselpartner(direccion:string) {
        let stContenidoPartner:any = document.getElementById("partner-carrusel");

        if (direccion === "derecha") {
            let itemprimeropartner = stContenidoPartner.childNodes[0];
            if (window.innerWidth < 580) {
                stContenidoPartner.style.marginLeft = "-100%";
            }
            else {
                stContenidoPartner.style.marginLeft = "-66.66%";
            }
            stContenidoPartner.style.transition = "all .5s";

            setTimeout(() => {
                stContenidoPartner.style.transition = "none";
                stContenidoPartner.insertAdjacentElement('beforeend', itemprimeropartner);

                if (window.innerWidth < 580) {
                    stContenidoPartner.style.marginLeft = "-50%";
                }
                else {
                    stContenidoPartner.style.marginLeft = "-33.33%";
                }
            }, 500);
        }
        else {
            let itempartner = stContenidoPartner.childNodes;
            let itemfinalpartner = itempartner[itempartner.length - 1];

            stContenidoPartner.style.marginLeft = "0%";
            stContenidoPartner.style.transition = "all 0.5s"

            setTimeout(() => {
                stContenidoPartner.style.transition = "none";
                stContenidoPartner.insertAdjacentElement('afterbegin', itemfinalpartner);
                stContenidoPartner.style.marginLeft = "-50%";
            }, 500);
        }
    }

    return (
        <div id="CMNosotros">
            <section id="nosotros__header">
                <h2>Nosotros</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Nosotros</li>
                </ul>
            </section>
            <section id="Nnosotros__section">
                <div id="Nnosotros__section-title">
                    <div id="Nnosotros__section-title--t1">
                        <p>Somos un equipo apasionado y<strong> dedicado de asesores y consultores.</strong></p>
                    </div>
                    <div id="Nnosotros__section-title--t2">
                        <p>Somos una empresa líder en la región orientados a solucionar de forma integral sus problemas tecnológicos, basado nuestro trabajo en una excelente atención
                            a nuestros clientes, satisfacción de sus necesidades tecnológicas y un soporte oportuno y adecuado ante cualquier eventualidad.</p>
                    </div>
                </div>
                <div id="Nnosotros-bar2">
                    <ul id="Nnosotros-bar2__lista">
                        <li>
                            <p>Bitdefender Antivirus 95% </p>
                            <div id="bit-95"></div>
                        </li>
                        <li>
                            <p>Kapersky Antivirus 85% </p>
                            <div id="kap-85"></div>
                        </li>
                        <li>
                            <p>Licenciamiento Microsoft 90% </p>
                            <div id="mic-90"></div>
                        </li>
                        <li>
                            <p>Servicios de Infraestructura 85% </p>
                            <div id="inf-85"></div>
                        </li>
                        <li>
                            <p>Distribucion de Hardware 90% </p>
                            <div id="har-90"></div>
                        </li>
                        <li>
                            <p>Soluciones de Networking 90% </p>
                            <div id="net-90"></div>
                        </li>
                    </ul>
                </div>
            </section>
            <section id="nosotros__sectiontext">
                <div id="item-filo">
                    <div id="item-filo__title">
                        <p>Filosofía</p>
                        <div></div>
                    </div>
                    <div id="item-filo__text">
                        <p id="item-filo__text--p1">Nuestra filosofía de trabajo apunta siempre a la excelencia, compromiso, integridad, flexibilidad y con total entrega en nuestros clientes,
                            características que nos dan un crédito más de valoración y confianza.</p>
                        <p id="item-filo__text--p2">Su satisfacción es nuestra filosofía. A sumimos cada proyecto como un nuevo reto, con responsabilidad y eficiencia tenemos muy en claro que para su satisfacción es
                            necesario articular un protocolo que incluye una serie de aspectos: como una estrecha colaboración que nos permite detectar sus necesidades, ofrecer soluciones a medida y
                            potenciar las relaciones duraderas.</p>
                    </div>
                </div>
                <div id="item-filo2">
                    <div id="item-vis">
                        <div id="item-vis__title">
                            <p>Visión</p>
                            <div></div>
                        </div>
                        <div id="item-vis__text">
                            <p>Seguir siendo el asesor de soluciones de TI más influyente y respetado de las empresas del medio.</p>
                        </div>
                    </div>
                    <div id="item-mis">
                        <div id="item-mis__title">
                            <p>Misión</p>
                            <div></div>
                        </div>
                        <div id="item-mis__text">
                            <p>Brindar soluciones de valor que permitan la optimización y productividad de la plataforma tecnológica de nuestros clientes, logrando alta satisfacción y fidelización.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="Npartner__section">
                <div id="partner-info">
                    <div id="partner-info__div">
                        <p id="partner-info__div--p1">Nuestros <strong> Partners.</strong></p>
                    </div>
                    <div id="partner-info__carrusel">
                        <ul id="partner-carrusel">
                            {
                                stPartners.map((item, index) => (
                                    <li key={index} className="partner-carrusel--item">
                                        <img src={item} alt="Infrasol Partners" />
                                    </li>
                                ))
                            }
                            
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default CMNosotros;