//Intranet
export const URL_INTRANET = "https://infrasol02.sharepoint.com/sites/Intranet_infrasol";

//Infrasol
export const API_SOLICITAR_TICKET = "https://api-infrasol.azurewebsites.net/api/SolicitarTicket";
export const API_SOLICITAR_INFORMACION = "https://api-infrasol.azurewebsites.net/api/EnviarCorreo";

//Social
export const API_WHATSAPP = "https://api.whatsapp.com/send?phone=51918057266";
export const API_FACEBOOK_PROPS =  {
    pageId: "1487273608252066",
    language: "en_US",
    themeColor: "#4267B2",
    loggedInGreeting: "loggedInGreeting",
    loggedOutGreeting: "Gracias por visitar Infrasol. Estaremos encantados de poder hablar contigo.",
};

//Ventana Emergente
export const SUCCESS = "success";
export const INFO = "info";
export const ERROR = "error";
export const QUESTION = "question";