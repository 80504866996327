import React, { useState } from 'react';
import { ModelHelpdesk, newHelpdesk } from '../../../../models/Helpdesk.model';
import { SolicitarTicket } from '../../../../services';
import alerta from '../../../../assets/img/desktop/advertencia.svg'
import n1 from '../../../../assets/img/desktop/N1.png'
import n2 from '../../../../assets/img/desktop/N2.png'
import n3 from '../../../../assets/img/desktop/N3.png'
import n4 from '../../../../assets/img/desktop/N4.png'
import './CDHelpDesk.css'

function CDHelpDesk() {

    const [objHelp, setObjHelp] = useState<ModelHelpdesk>(newHelpdesk);
    const [alerta01, setAlerta01] = useState("alerta01-off");
    const [alerta02, setAlerta02] = useState("alerta02-off");
    const [alerta03, setAlerta03] = useState("alerta03-off");
    const [alerta04, setAlerta04] = useState("alerta04-off");
    const [alerta05, setAlerta05] = useState("alerta05-off");

    function cargarinfo(event:any) {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        
        if (name === "telefono") {
            if (value.length <= 9) {
                setObjHelp({
                    ...objHelp,
                    [name]: value
                });
            }
        } else {
            setObjHelp({
                ...objHelp,
                [name]: value
            });
        }

        var validar = false;

        if (name !== "categorias") {
            while (validar === false) {
                switch (name) {
                    case "empresa":
                        if (value !== "") {
                            setAlerta01("alerta01-off");
                            validar = true;
                        }
                        else {
                            validar = true;
                        }
                        break;
                    case "contacto":
                        if (value !== "") {
                            setAlerta02("alerta02-off");
                            validar = true;
                        }
                        else {
                            validar = true;
                        }
                        break;
                    case "correo":
                        if (value !== "") {
                            setAlerta03("alerta03-off");
                            validar = true;
                        }
                        else {
                            validar = true;
                        }
                        break;
                    case "telefono":
                        if (value !== "") {
                            setAlerta04("alerta04-off");
                            validar = true;
                        }
                        else {
                            validar = true;
                        }
                        break;
                    default:
                        if (value !== "") {
                            setAlerta05("alerta05-off");
                            validar = true;
                        }
                        else {
                            validar = true;
                        }
                        break;
                }
            }
        }
    }

    function validacion() {
        if (objHelp.empresa === "" && objHelp.contacto === "" && objHelp.correo === "" && objHelp.telefono === "" && objHelp.descripcion === "") {
            setAlerta01("alerta01-on");
        }
        else if (objHelp.empresa !== "" && objHelp.contacto !== "" && objHelp.correo !== "" && objHelp.telefono !== "" && objHelp.descripcion !== "") {
           
            let request:any = SolicitarTicket(objHelp);

            setTimeout(() => {
                if (request) {
                    setObjHelp({
                        ...objHelp,
                        empresa: "",
                        contacto: "",
                        correo: "",
                        telefono: "",
                        descripcion: ""
                    });
                }
            }, 3000);
        }
        else {
            var interruptor = true;
            while (interruptor === true) {
                if (objHelp.empresa === "") {
                    setAlerta01("alerta01-on");
                    interruptor = false;
                    break;
                } else {
                    setAlerta01("alerta01-off");
                    interruptor = true;
                }
                if (objHelp.contacto === "") {
                    setAlerta02("alerta02-on");
                    interruptor = false;
                    break;
                } else {
                    setAlerta02("alerta02-off");
                    interruptor = true;
                }
                if (objHelp.correo === "") {
                    setAlerta03("alerta03-on");
                    interruptor = false;
                    break;
                } else {
                    setAlerta03("alerta03-off");
                    interruptor = true;
                }
                if (objHelp.telefono === "") {
                    setAlerta04("alerta04-on");
                    interruptor = false;
                    break;
                } else {
                    setAlerta04("alerta04-off");
                    interruptor = true;
                }
                if (objHelp.descripcion === "") {
                    setAlerta05("alerta05-on");
                    interruptor = false;
                    break;
                } else {
                    setAlerta05("alerta05-off");
                    interruptor = true;
                }
            }
        }
    }

    return (
        <main id="CDHelp">
            <section id="CDHD-c1">
                <h2>Mesa de Ayuda</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Mesa de Ayuda</li>
                </ul>
            </section>
            <section id="CDHD-c2">
                <div id="CDHD-c2__item2">
                    <h2>Procedimiento Mesa de Ayuda</h2>
                    <div id="CDHD-c2__item2--text">
                        <div className="text-item">
                            <div className="text-item__image">
                                <figure>
                                    <img src={n1} />
                                </figure>
                            </div>
                            <div className="text-item__text">
                                <h4>Ingreso de solicitud </h4>
                                <p >Completar y enviar la Solicitud de Soporte o remitirnos un correo a<strong> soporte@infra-sol.com</strong></p>
                            </div>
                        </div>
                        <div className="text-item">
                            <div className="text-item__image">
                                <figure>
                                    <img src={n2} />
                                </figure>
                            </div>
                            <div className="text-item__text">
                                <h4 >Generación del código de caso </h4>
                                <p >Se genera un código de atención del caso que se deriva automáticamente al área de soporte especializada.</p>
                            </div>
                        </div>
                        <div className="text-item">
                            <div className="text-item__image">
                                <figure>
                                    <img src={n3} />
                                </figure>
                            </div>
                            <div className="text-item__text">
                                <h4 >Asignación y solución del caso </h4>
                                <p >El caso es asignado a un especialista según la categoría, que se pondrá en comunicación con el contacto que generó la solicitud para su pronta atención y solución.</p>
                            </div>
                        </div>
                        <div className="text-item">
                            <div className="text-item__image">
                                <figure>
                                    <img src={n4} />
                                </figure>
                            </div>
                            <div className="text-item__text">
                                <h4 > Cierre del caso</h4>
                                <p >Resuelto el caso, se informará al contacto para que nos brinde su conformidad, seguidamente se cerrará el caso.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="CDHD-c2__item1">
                    <h2>Solicitud de Soporte Infrasol</h2>
                    <div id="CDHD-c2__item1--form">
                        <div id="form-title"></div>
                        <div className="form-item">
                            <div className="form-item__title" >Cliente</div>
                            <div className="form-item__input">
                                <input type="text" name="empresa" value={objHelp.empresa} onChange={cargarinfo} />
                                <figure id={alerta01}>
                                    <img src={alerta} />
                                    <p>Rellene este campo</p>
                                </figure >
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-item__title" >Contacto</div>
                            <div className="form-item__input">
                                <input type="text" name="contacto" value={objHelp.contacto} onChange={cargarinfo} />
                                <figure id={alerta02}>
                                    <img src={alerta} />
                                    <p>Rellene este campo</p>
                                </figure >
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-item__title">Email </div>
                            <div className="form-item__input">
                                <input type="email" name="correo" value={objHelp.correo} onChange={cargarinfo} />
                                <figure id={alerta03}>
                                    <img src={alerta} />
                                    <p>Rellene este campo</p>
                                </figure >
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-item__title">Teléfono</div>
                            <div className="form-item__input">
                                <input type="number" name="telefono" value={objHelp.telefono} onChange={cargarinfo} />
                                <figure id={alerta04}>
                                    <img src={alerta} />
                                    <p>Rellene este campo</p>
                                </figure >
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-item__title">Descripción</div>
                            <div className="form-item__input">
                                <textarea cols={ 5 } rows={ 4 } name="descripcion" value={objHelp.descripcion} onChange={cargarinfo}></textarea>
                                <figure id={alerta05}>
                                    <img src={alerta} />
                                    <p>Rellene este campo</p>
                                </figure >
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-item__title">Categoría</div>
                            <div className="form-item__input">
                                <select name="categorias" value={objHelp.categorias} onChange={cargarinfo}>
                                    <option value="Bitdefender Empresas">Bitdefender Empresas</option>
                                    <option value="Kapersky Empresas">Kapersky Empresas</option>
                                    <option value="Soporte Help Desk">Soporte Help Desk</option>
                                </select>
                            </div>
                        </div>
                        <div id="form-lineas">
                            <p>* En el campo Cliente, ingresar el nombre de la empresa </p>
                            <p>* Es importante completar todos los campos  </p>
                        </div>
                        <div id="form-button" >
                            <p onClick={validacion}>Enviar</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default CDHelpDesk;