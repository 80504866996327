import React from 'react';
import { useNavigate } from 'react-router-dom';
import mapa from '../../../../assets/img/desktop/map.svg';
import './CDFooter.css';

function CDFooter() {

    const navigate = useNavigate();

    function loadViewMovil(name: string) {
        navigate("/" + name);
    }

    return (
        <footer id="CDFooter">
            <div id="CDF-content">
                <div id="CDF-content__c1">
                    <p>NUESTRA OFICINA</p>
                    <div id="CD-content__c1--ubicacion">
                        <div id="CD-content__c1--ubicacion---image">
                            <figure>
                                <img src={mapa} alt="Ubicacion Infrasol"/>
                            </figure>
                        </div>
                        <div id="CD-content__c1--ubicacion---text">
                            <p id="CD-content__c1--ubicacion---text_p1">Lima</p>
                            <p id="CD-content__c1--ubicacion---text_p2">Calle Elías Aguirre Nº 180 Miraflores</p>
                            <p id="CD-content__c1--ubicacion---text_p3">Central T. +51(1) 739-2588</p>
                        </div>
                    </div>
                </div>
                <div id="CDF-content__c2">
                    <p id="CDF-content__c2--p1">Informes y Ventas</p>
                    <p id="CDF-content__c2--p2">ventas@infra-sol.com</p>
                </div>
                <div id="CDF-content__c3">
                    <p>SERVICIOS</p>
                    <ul>
                        <li onClick={() => loadViewMovil("Bitdefender")} className="CDF-footer-li">Bitdefender Antivirus</li>
                        <li onClick={() => loadViewMovil("Kaspersky")} className="CDF-footer-linea">Kaspersky Antivirus</li>
                        <li onClick={() => loadViewMovil("Microsoft")} className="CDF-footer-linea">Licenciamiento Microsoft</li>
                        <li onClick={() => loadViewMovil("Infraestructura")} className="CDF-footer-linea">Servicios de Infraestructura</li>
                        <li onClick={() => loadViewMovil("Networking")} className="CDF-footer-linea">Soluciones en Networking</li>
                        <li onClick={() => loadViewMovil("Hardware")} className="CDF-footer-linea">Distribución de Hardware</li>
                        <li onClick={() => loadViewMovil("Antispam")} className="CDF-footer-linea">Antispam</li>
                    </ul>
                </div>
            </div>
            <div id="CDF-bar">
                <p>© Infrasol - Todos los Derechos Reservados</p>
            </div>
        </footer>
    );
}

export default CDFooter;