import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import microsoft1 from '../../../../assets/img/movil/6.jpg';
import next from '../../../../assets/img/movil/arrow.svg';
import microsoft2 from '../../../../assets/img/movil/banner25.jpg';
import carrusel1 from '../../../../assets/img/movil/bitdefender.jpg';
import bannerbit2 from '../../../../assets/img/movil/BitdefenderGold.png';
import bannerbit1 from '../../../../assets/img/movil/Fondblue.png';
import kapersky2 from '../../../../assets/img/movil/karpesky1.png';
import kapersk3 from '../../../../assets/img/movil/karpesky2.jpg';
import kapersky1 from '../../../../assets/img/movil/karpesky3.jpg';
import carrusel2 from '../../../../assets/img/movil/kaspersky.jpg';
import c1 from '../../../../assets/img/movil/Bitdefender Antivirus.png';
import c2 from '../../../../assets/img/movil/Kaspersky Antivirus.png';
import c3 from '../../../../assets/img/movil/Licenciamiento Microsoft.png';
import c4 from '../../../../assets/img/movil/Servicios de Infraestructura.png';
import c5 from '../../../../assets/img/movil/Soluciones de Networking.png';
import c6 from '../../../../assets/img/movil/Distribucion de Hardware.png';
import c8 from '../../../../assets/img/movil/Antispam.png';
import carrusel3 from '../../../../assets/img/movil/microsoft.jpg';
import security1 from '../../../../assets/img/movil/Servicios-infraestructura.jpg';
import security2 from '../../../../assets/img/movil/Soluciones-Networking.jpg';
import partner1 from '../../../../assets/img/movil/Zebra_logo.png';
import partner2 from '../../../../assets/img/movil/MicrosoftTeams-image.png';
import partner3 from '../../../../assets/img/movil/Microsoft_logo_(2012).svg.png';
import partner4 from '../../../../assets/img/movil/Partner4.jpg';
import partner5 from '../../../../assets/img/movil/Partner5.jpg';
import partner6 from '../../../../assets/img/movil/Bitdefender-Logo.wine.png';
import partner7 from '../../../../assets/img/movil/Autodesk_Logo.svg.png';
import partner8 from '../../../../assets/img/movil/351497.png';
import partner9 from '../../../../assets/img/movil/350655.png';
import partner10 from '../../../../assets/img/movil/2048px-HP_New_Logo_2D.svg.png';
import partner11 from '../../../../assets/img/movil/1280px-Epson_logo.svg.png';
import partner12 from '../../../../assets/img/movil/1200px-Kaspersky_Lab_logo.svg.png';
import partner13 from '../../../../assets/img/movil/logo_heimdal_security.png';
import partner14 from '../../../../assets/img/movil/Lenovo_logo_2015.svg.png';
import partner15 from '../../../../assets/img/movil/Partner15.jpg';
import partner16 from '../../../../assets/img/movil/Dell_Logo.svg.png';
import './CMHome.css';

function CMHome() {

    const navigate = useNavigate();

    const [seccion_A] = useState("EstiloA-mostrado");
    const [seccion_D] = useState("EstiloD-mostrado");
    const [stPartners] = useState([partner1, partner2, partner3, partner4, partner5, partner6, partner7, partner8, partner9, partner10, partner11, partner12, partner13, partner14, partner15, partner16]);

    useEffect(() => {
        let idInterval = setInterval(() => {
            if (window.location.pathname === "/") {
                try {
                    movercarrusel("derecha");
                    movercarruselpartner("derecha");
                } catch(e) {
                    window.location.reload();
                }
            } else {
                window.clearInterval(idInterval);
            }
        }, 5000);
    }, []);

    function movercarrusel(direccion:string) {
        let stContenido:any = document.getElementById("carrusel-container");

        if (direccion === "derecha") {
            let itemPrimero = stContenido.childNodes[0];
            stContenido.style.marginLeft = "-200%";
            stContenido.style.transition = "all .5s";

            setTimeout(() => {
                stContenido.style.transition = "none";
                stContenido.insertAdjacentElement('beforeend', itemPrimero);
                stContenido.style.marginLeft = "-100%";
            }, 500);
        }
        else {
            let itemFinal = stContenido.childNodes[stContenido.childNodes.length - 1];
            stContenido.style.marginLeft = "0%";
            stContenido.style.transition = "all 0.5s"

            setTimeout(() => {
                stContenido.style.transition = "none";
                stContenido.insertAdjacentElement('afterbegin', itemFinal);
                stContenido.style.marginLeft = "-100%";
            }, 500);
        }
    }

    function movercarruselpartner(direccion:string) {
        let stContenidoPartner:any = document.getElementById("partner-carrusel");

        if (direccion === "derecha") {
            let itemPrimeropartner = stContenidoPartner.childNodes[0];

            if (window.innerWidth < 580) {
                stContenidoPartner.style.marginLeft = "-100%";
            }
            else {
                stContenidoPartner.style.marginLeft = "-66.66%";
            }
            stContenidoPartner.style.transition = "all .5s";

            setTimeout(() => {
                stContenidoPartner.style.transition = "none";
                stContenidoPartner.insertAdjacentElement('beforeend', itemPrimeropartner);

                if (window.innerWidth < 580) {
                    stContenidoPartner.style.marginLeft = "-50%";
                }
                else {
                    stContenidoPartner.style.marginLeft = "-33.33%";
                }
            }, 500);
        }
        else {
            let itempartner = stContenidoPartner.childNodes;
            let itemfinalpartner = itempartner[itempartner.length - 1];

            stContenidoPartner.style.marginLeft = "0%";
            stContenidoPartner.style.transition = "all 0.5s"

            setTimeout(() => {
                stContenidoPartner.style.transition = "none";
                stContenidoPartner.insertAdjacentElement('afterbegin', itemfinalpartner);
                stContenidoPartner.style.marginLeft = "-50%";
            }, 500);
        }
    }

    function navigateView(name:string) {
        navigate("/" + name);
    }

    return (
        <main id="CMHome">
            <section id="carrusel__section" >
                <ul id="carrusel-container">
                    <li id="carrusel-container__item">
                        <img src={carrusel3} alt="Infrasol SAC" />
                        <div id="carrusel-content3">
                            <div id="carrusel-content__text-hide3">
                                <h3>Licenciamiento Microsoft</h3>
                                <p>Adquiera sus licencias Microsoft con nosotros y licencie correctamente.</p>
                            </div>
                            <div id="carrusel-content__text3" className={seccion_D}>
                                <h3 >Licenciamiento Microsoft </h3>
                                <p >Adquiera sus licencias Microsoft con nosotros y licencie correctamente. </p>
                            </div>
                            <div id="carrusel-content__query">
                                <p onClick={() => navigateView("Microsoft")}>Consultar</p>
                            </div>
                        </div>
                    </li>
                    <li id="carrusel-container__item">
                        <img src={carrusel1} alt="Infrasol SAC" />
                        <div id="carrusel-content">
                            <div id="carrusel-content__text-hide">
                                <h3>Bitdefender líder mundial en seguridad informática</h3>
                                <p>Más de 500 millones de sistemas protegidos durante más de 18 años.</p>
                            </div>
                            <div id="carrusel-content__text" className={seccion_A}>
                                <h3>Bitdefender líder mundial en seguridad informática</h3>
                                <p>Más de 500 millones de sistemas protegidos durante más de 18 años.</p>
                            </div>
                            <div id="carrusel-content__query" >
                                <p onClick={() => navigateView("Bitdefender")}>Consultar</p>
                            </div>
                        </div>
                    </li>
                    <li id="carrusel-container__item">
                        <img src={carrusel2} alt="Infrasol SAC" />
                        <div id="carrusel-content2">
                            <div id="carrusel-content__text2">
                                <h3>Kaspersky para empresas</h3>
                                <p>Soluciones de verdadera ciberseguridad de última generación
                                    para predecir, evitar y detectar ciberataques, asi como para dar respuesta a ellos.</p>
                            </div>
                            <div id="carrusel-content__query">
                                <p onClick={() => navigateView("Kaspersky")}>Consultar</p>
                            </div>
                        </div>
                    </li>

                </ul>
                <div id="point-next" onClick={() => movercarrusel("izquierda")}>
                    <div >
                        <img className="img-left" src={next} alt="Infrasol SAC" />
                    </div>
                </div>
                <div id="point-next2" onClick={() => movercarrusel("derecha")}>
                    <div>
                        <img className="img-right" src={next} alt="Infrasol SAC" />
                    </div>
                </div>
            </section>
            <section id="options__section">
                <div id="options-title">
                    <p id="options-title__p1" >SERVICIOS</p>
                    <h1 id="options-title__h1">
                        <span>Soluciones</span>
                        <strong> Tecnológicas</strong>
                    </h1>
                </div>
                <ul id="options-multiple">
                    <li onClick={() => navigateView("Bitdefender")}>
                        <img className="img-check" src={c1} alt="Infrasol SAC" />
                        <p className="p-subtitle">Bitdefender Antivirus</p>
                    </li>
                    <li onClick={() => navigateView("Kaspersky")}>
                        <img className="img-check" src={c2} alt="Infrasol SAC" />
                        <p className="p-subtitle">Kaspersky Antivirus</p>
                    </li>
                    <li onClick={() => navigateView("Microsoft")}>
                        <img className="img-check" src={c3} alt="Infrasol SAC" />
                        <p className="p-subtitle">Licenciamiento Microsoft</p>
                    </li>
                    <li onClick={() => navigateView("Infraestructura")}>
                        <img className="img-check" src={c4} alt="Infrasol SAC" />
                        <p className="p-subtitle">Servicios de Infraestructura</p>
                    </li>
                    <li onClick={() => navigateView("Networking")}>
                        <img className="img-check" src={c5} alt="Infrasol SAC" />
                        <p className="p-subtitle">Soluciones en Networking</p>
                    </li>
                    <li onClick={() => navigateView("Hardware")}>
                        <img className="img-check" src={c6} alt="Infrasol SAC" />
                        <p className="p-subtitle">Distribucion de Hardware</p>
                    </li>
                    <li onClick={() => navigateView("Antispam")}>
                        <img className="img-check" src={c8} alt="Infrasol SAC" />
                        <p className="p-subtitle">Antispam</p>
                    </li>
                </ul>
            </section>
            <section id="bit__section">
                <figure id="bit-fondo">
                    <img src={bannerbit1} alt="Infrasol SAC" />
                    <div id="bit-content">
                        <div id="bit-content__c1">
                            <h1 id="bit-content__c1--h1">DESCUBRA LAS AMENAZAS EVASIVAS Y RESPONDA EFIZCAMENTE</h1>
                            <p id="bit-content__c1--p1">Defienda su organizacion contra las amenazas digitales con capacidades de seguridad avanzadas integradas,
                                a un precio adecuado para su empresa.</p>
                            <p id="bit-content__c1--p2">Somos Partner Gold, confie su seguridad en Bitdefender y proteja sus servidores y estaciones de trabajo.</p>
                        </div>
                        <figure id="bit-content__c2">
                            <img src={bannerbit2} alt="Infrasol SAC" />
                        </figure>
                        <div id="bit-content__c3">
                            <p onClick={() => navigateView("Bitdefender")}>Consultar</p>
                        </div>
                    </div>
                </figure>
            </section>
            <section id="nosotros__section">
                <div id="nosotros-title">
                    <div id="nosotros-title__t1">
                        <p>NOSOTROS</p>
                    </div>
                    <div id="nosotros-title__t2">
                        <p>Somos un equipo apasionado y<strong> dedicado de asesores y consultores.</strong></p>
                    </div>
                    <div id="nosotros-title__t3">
                        <p>Comunicaciones, Infraestructura y Seguridad Informática</p>
                        <div>
                            <p onClick={() => navigateView("Nosotros")}>Conócenos</p>
                        </div>
                    </div>
                </div>
                <div id="nosotros-bar">
                    <ul id="nosotros-bar__lista">
                        <li>
                            <p>Bitdefender Antivirus 95% </p>
                            <div id="bit-95"></div>
                        </li>
                        <li>
                            <p>Kapersky Antivirus 85% </p>
                            <div id="kap-85"></div>
                        </li>
                        <li>
                            <p>Licenciamiento Microsoft 90% </p>
                            <div id="mic-90"></div>
                        </li>
                        <li>
                            <p>Servicios de Infraestructura 85% </p>
                            <div id="inf-85"></div>
                        </li>
                        <li>
                            <p>Distribucion de Hardware 90% </p>
                            <div id="har-90"></div>
                        </li>
                        <li>
                            <p>Soluciones de Networking 90% </p>
                            <div id="net-90"></div>
                        </li>
                    </ul>
                </div>
            </section>
            <section id="kasp__section">
                <figure id="kasp-fondo">
                    <img src={kapersky1} alt="Infrasol SAC" />
                    <div id="kasp-content">
                        <div id="kasp-c1">
                            <figure id="kasp-content__c1">
                                <img src={kapersky2} alt="Infrasol SAC" />
                            </figure>
                            <div id="kasp-content__c2">
                                <p onClick={() => navigateView("Kaspersky")}>Consultar</p>
                            </div>
                        </div>
                        <div id="kasp-c2">
                            <figure id="kasp-content__c3">
                                <img src={kapersk3} alt="Infrasol SAC" />
                            </figure>
                        </div>
                    </div>
                </figure>
            </section>
            <section id="security__section">
                <div className="security-item">
                    <img className="security-item__img" src={security1} alt="Infrasol SAC" />
                    <div className="security-item__div">
                        <p>Servicios de Infraestructura</p>
                        <span>Servicios integrales de gestión que dan respuesta a las necesidades de la plataforma de TI de las empresas.</span>
                    </div>
                </div>
                <div className="security-item2">
                    <img className="security-item__img" src={security2} alt="Infrasol SAC" />
                    <div className="security-item__div2">
                        <p>Soluciones en Networking</p>
                        <span>Ofrece consultoría, gestión de proyectos, planificación, testeo, mantenimiento y soporte en Networking.</span>
                    </div>
                </div>
            </section>
            <section id="microsoft__section">
                <figure id="microsoft-fondo">
                    <img src={microsoft1} alt="Infrasol SAC" />
                    <div id="microsoft-content">
                        <div id="microsoft-content__c1" >
                            <figure>
                                <img src={microsoft2} alt="Infrasol SAC" />
                            </figure>
                        </div>
                        <div id="microsoft-content__c2">
                            <p onClick={() => navigateView("Microsoft")}>Consultar</p>
                        </div>
                    </div>

                </figure>
            </section>
            <section id="partner__section">
                <div id="partner-info">
                    <div id="partner-info__div">
                        <p id="partner-info__div--p1">Nuestros <strong> Partners.</strong></p>
                    </div>
                    <div id="partner-info__carrusel">
                        <ul id="partner-carrusel">
                            {
                                stPartners.map((item, index) => (
                                    <li key={index} className="partner-carrusel--item">
                                        <img src={item} alt="Infrasol Partners" />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default CMHome;