import React from "react";
import { useViewport } from '../../../hooks';
import { CDHeader, CDSocial, CDFooter } from '../../organisms/desktop';
import { CMHeader, CMSocial, CMFooter } from '../../organisms/movil';

const View = ({ children } : any) => {

    const { width } = useViewport();

    return (
        width > 850 ?
            <>
                <CDHeader />
                { children }
                <CDSocial />
                <CDFooter />
            </>
            :
            <>
                <CMHeader />
                { children }
                <CMSocial />
                <CMFooter />
            </>
    );
}

export default View;