import React from 'react';
import kas1 from '../../../../assets/img/desktop/kapersky.jpg';
import kas2 from '../../../../assets/img/desktop/kars.jpg';
import kas3 from '../../../../assets/img/desktop/premiada.jpg';
import kas4 from '../../../../assets/img/desktop/recomendado.jpg';
import kas5 from '../../../../assets/img/desktop/transparente.jpg';
import './CDKaspersky.css';

function CDKaspersky() {

    return (
        <main id="CDKaspersky">
            <section id="CDD-c1">
                <h2>Kaspersky Antivirus</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Servicios</li>
                    <li>/</li>
                    <li>Kaspersky Antivirus</li>
                </ul>
            </section>
            <section id="CDK-c2">
                <figure id="CDK-c2__image">
                    <img src={kas1} />
                </figure>
                <div id="CDK-c2__text">
                    <h4 >Ciberseguridad sencilla para las empresas en crecimiento.</h4>
                    <p >La mejor seguridad disponible facilita el trabajo de los sobrecargados departamentos de TI, no lo dificulta.
                        Si su empresa está creciendo rápido o si se encuentra en plena transformación digital, es posible que los recursos estén continuamente sobrecargados.
                        Por lo tanto, debe trabajar de manera inteligente: seleccione soluciones de endpoint que ofrecen protección instantánea y
                        están listas para escalarse cuando lo necesite.</p>
                </div>
            </section>
            <section id="CDK-c3">
                <section>
                    <div id="CDK-c3__title">
                        <h2>¿Qué protección es mejor para su negocio?</h2>
                    </div>
                    <div id="CDK-c3__image">
                        <figure>
                            <img src={kas2} />
                        </figure>
                    </div>
                </section>
            </section>
            <section id="CDK-c4">
                <h2>¿Qué protección es mejor para su negocio?</h2>
                <div>
                    <div className="CDK-c4__item">
                        <figure className="CDK-c4__item--image">
                            <img src={kas3} />
                        </figure>
                        <div className="CDK-c4__item--text">
                            <h2>La más probada. La más premiada.</h2>
                            <p>Kaspersky Lab ha conseguido la mayor cantidad de primeros lugares en pruebas independientes que cualquier otro proveedor de seguridad.
                                Y eso lo conseguimos todos los años.</p>
                        </div>
                    </div>
                    <div className="CDK-c4__item">
                        <figure className="CDK-c4__item--image">
                            <img src={kas4} />
                        </figure>
                        <div className="CDK-c4__item--text">
                            <h2>El más recomendado</h2>
                            <p>Kaspersky Lab vuelve a ser nombrado en los Peer Insights Customers Choise de Gartner del 2018 por las plataformas de protección de endpoints,
                                con una alta puntuación de satisfacción de los clientes de 4.7 de 5.</p>
                        </div>
                    </div>
                    <div className="CDK-c4__item">
                        <figure className="CDK-c4__item--image">
                            <img src={kas5} />
                        </figure>
                        <div className="CDK-c4__item--text">
                            <h2>Más transparente</h2>
                            <p>Con nuestro primer centro de transparencia ahora activo y un procesamiento estadístico situado en Suiza, la soberanía de sus datos se garantiza de maneras
                                que ningún otro proveedor puede igualar.</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default CDKaspersky;