import { useState } from 'react';
import { ModelContacto, newContacto } from '../../../../models/Contacto.model';
import { SolicitarInformacion } from '../../../../services'
import mapa from '../../../../assets/img/desktop/map.svg'
import telefono from '../../../../assets/img/desktop/telefono.svg'
import email from '../../../../assets/img/desktop/email.svg'
import alerta from '../../../../assets/img/desktop/advertencia.svg'
import './CDContacto.css';

function CDContacto() {

    const [objContacto, setObjContacto] = useState<ModelContacto>(newContacto);
    const [CDCalerta01, setCDCalerta01] = useState("CDCalerta01-off");
    const [CDCalerta02, setCDCalerta02] = useState("CDCalerta02-off");
    const [CDCalerta03, setCDCalerta03] = useState("CDCalerta03-off");
    const [CDCalerta04, setCDCalerta04] = useState("CDCalerta04-off");
    const [CDCalerta05, setCDCalerta05] = useState("CDCalerta05-off");

    function cargardatos(event:any) {
        var target = event.target;
        var value = target.value;
        var name = target.name;

        if (name === "telefono") {
            if (value.length <= 9) {
                setObjContacto({
                    ...objContacto,
                    [name]: value
                })
            }
        } else {
            setObjContacto({
                ...objContacto,
                [name]: value
            })
        }
        
        var validar = false;

        while (validar === false) {

            switch (name) {
                case "nombres":
                    if (value !== "") {
                        setCDCalerta01("CDCalerta01-off");
                        validar = true;
                    }
                    else {
                        validar = true;
                    }
                    break;
                case "apellidos":
                    if (value !== "") {
                        setCDCalerta02("CDCalerta02-off");
                        validar = true;
                    }
                    else {
                        validar = true;
                    }
                    break;
                case "telefono":
                    if (value !== "") {
                        setCDCalerta03("CDCalerta03-off");
                        validar = true;
                    }
                    else {
                        validar = true;
                    }
                    break;
                case "correo":
                    if (value !== "") {
                        setCDCalerta04("CDCalerta04-off");
                        validar = true;
                    }
                    else {
                        validar = true;
                    }
                    break;
                case "mensaje":
                    if (value !== "") {
                        setCDCalerta05("CDCalerta05-off");
                        validar = true;
                    }
                    else {
                        validar = true;
                    }
                    break;
                default:
                    validar = true;
                    break;
            }
        }
    }

    function validarcontactoD() {
        if (objContacto.nombres === "" && objContacto.apellidos === "" && objContacto.telefono === "" && objContacto.correo === "" && objContacto.mensaje === "") {
            setCDCalerta01("CDCalerta01-on");
        }
        else if (objContacto.nombres !== "" && objContacto.apellidos !== "" && objContacto.telefono !== "" && objContacto.correo !== "" && objContacto.mensaje !== "") {
            
            let request:any = SolicitarInformacion(objContacto);
            
            setTimeout(() => {
                if (request) {
                    setObjContacto({
                        nombres: "",
                        apellidos: "",
                        telefono: "",
                        correo: "",
                        mensaje: ""
                    });
                }
            }, 3000);
        }
        else {
            var interruptor = true;
            while (interruptor === true) {
                if (objContacto.nombres === "") {
                    setCDCalerta01("CDCalerta01-on");
                    interruptor = false;
                    break;
                } else {
                    setCDCalerta01("CDCalerta01-off");
                    interruptor = true;
                }
                if (objContacto.apellidos === "") {
                    setCDCalerta02("CDCalerta02-on");
                    interruptor = false;
                    break;
                } else {
                    setCDCalerta02("CDCalerta02-off");
                    interruptor = true;
                }
                if (objContacto.telefono === "") {
                    setCDCalerta03("CDCalerta03-on");
                    interruptor = false;
                    break;
                } else {
                    setCDCalerta03("CDCalerta03-off");
                    interruptor = true;
                }
                if (objContacto.correo === "") {
                    setCDCalerta04("CDCalerta04-on");
                    interruptor = false;
                    break;
                } else {
                    setCDCalerta04("CDCalerta04-off");
                    interruptor = true;
                }
                if (objContacto.mensaje === "") {
                    setCDCalerta05("CDCalerta05-on");
                    interruptor = false;
                    break;
                } else {
                    setCDCalerta05("CDCalerta05-off");
                    interruptor = true;
                }
            }
        }
    }

    return (
        <main id="CDContacto">
            <section id="CDC-c1">
                <h2>Contacto</h2>
                <ul>
                    <li>Inicio</li>
                    <li>/</li>
                    <li>Contacto</li>
                </ul>
            </section>
            <section id="CDC-c2">
                <div id="CDC-c2__image">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d487.6136143321194!2d-77.03488246586949!3d-12.118384459389286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c83d444f9753%3A0x8255648301793661!2sEl%C3%ADas%20Aguirre%20180%2C%20Miraflores%2015074!5e0!3m2!1ses-419!2spe!4v1633542944251!5m2!1ses-419!2spe" width="600" height="350" loading="lazy"></iframe>
                </div>
                <div id="CDC-c2__form">
                    <h2>Solicitar <strong>Información</strong></h2>
                    <p></p>
                    <div id="CDC-c2__form--content">
                        <input type="text" placeholder="Nombres" name="nombres" value={objContacto.nombres} onChange={cargardatos} />
                        <figure id={CDCalerta01}>
                            <img src={alerta} />
                            <p>Rellene este campo</p>
                        </figure >
                        <input type="text" placeholder="Apellidos" name="apellidos" value={objContacto.apellidos} onChange={cargardatos} />
                        <figure id={CDCalerta02}>
                            <img src={alerta} />
                            <p>Rellene este campo</p>
                        </figure >
                        <input type="number" placeholder="Telefono" name="telefono" value={objContacto.telefono} onChange={cargardatos} />
                        <figure id={CDCalerta03}>
                            <img src={alerta} />
                            <p>Rellene este campo</p>
                        </figure >
                        <input type="email" placeholder="Email" name="correo" value={objContacto.correo} onChange={cargardatos} />
                        <figure id={CDCalerta04}>
                            <img src={alerta} />
                            <p>Rellene este campo</p>
                        </figure >
                    </div>
                    <textarea placeholder="Escriba su mensaje" cols={5} rows={3} name="mensaje" value={objContacto.mensaje} onChange={cargardatos}></textarea>
                    <figure id={CDCalerta05}>
                        <img src={alerta} />
                        <p>Rellene este campo</p>
                    </figure>
                    <div id="CDC-c2__form--button" onClick={validarcontactoD}>
                        <p>ENVIAR</p>
                    </div>
                </div>
            </section>
            <section id="CDC-c3">
                <div className="CDC-c3__item" >
                    <div className="CDC-c3__item--image">
                        <figure>
                            <img src={email} />
                        </figure>
                    </div>
                    <div className="CDC-c3__item--text">
                        <h4 >Email</h4>
                        <p >ventas@infra-sol.com</p>
                    </div>
                </div>
                <div className="CDC-c3__item" >
                    <div className="CDC-c3__item--image">
                        <figure>
                            <img src={telefono} />
                        </figure>
                    </div>
                    <div className="CDC-c3__item--text" >
                        <h4>Teléfono</h4>
                        <p >Central.</p>
                        <p>T. +51(1) 233-1927</p>
                    </div>
                </div>
                <div className="CDC-c3__item" >
                    <div className="CDC-c3__item--image">
                        <figure>
                            <img src={mapa} />
                        </figure>
                    </div>
                    <div className="CDC-c3__item--text">
                        <h4 >Direccion</h4>
                        <p >Calle Elias Aguirre N° 180.</p>
                        <p >Miraflores-Lima</p>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default CDContacto;