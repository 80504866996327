import React from "react";
import { View } from '../../../components/templates/View';
import { CDNosotros, CMNosotros } from '../organisms';
import { useViewport } from "../../../hooks";

const Nosotros = () => {

    const { width } = useViewport();

    return(
        width > 850 ?
            <>
                <View children={ <CDNosotros /> }/>
            </>
        :
            <>
                <View children={ <CMNosotros/> }/>
            </>
    );
}

export default Nosotros;